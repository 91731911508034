import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Phase } from '../model';
import { Payment } from '../../payments/model';
import getStatusColor from '../../interviews/constants/getStatusColor';
import siteWideStyles from '../../styles/siteWideStyles';

export const paymentColumns: IDataTableColumn<any>[] = [
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Amount',
    selector: 'number_amount',
    sortable: true,
    cell: ({ number_amount }) => <div>${number_amount}</div>,
  },
  {
    name: 'Notes',
    selector: 'notes',
    sortable: true,
  },
  {
    name: 'Processed At',
    selector: 'processed_at',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status),
        }}
      >
        {status}
      </div>
    ),
  },
];

interface PhasePaymentsProps {
  phase: Phase;
  payments: Payment[];
  title: string;
  category: string;
}

const PhasePayments: React.FC<PhasePaymentsProps> = (props) => {
  const { payments, phase, title, category } = props;
  const history = useHistory();

  return (
    <Card style={{ padding: '2%' }}>
      <div style={{ marginBottom: 15 }}>
        <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
          <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
            <h2 className="Polaris-Heading">{title}s</h2>
          </div>
          <div className="Polaris-Stack__Item">
            <div className="Polaris-ButtonGroup">
              <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                <Button
                  onClick={() =>
                    history.push(`/admin/projects/${phase?.project_id}/${category}/new?phase_id=${phase?.id}`)
                  }
                  style={siteWideStyles?.primaryButton}
                >
                  Add {title}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        onRowClicked={(payment: Payment) => history.push(`/admin/${category}/${payment?.id}`)}
        columns={paymentColumns}
        data={payments}
        pagination
        striped
        highlightOnHover
        pointerOnHover
        noHeader
      />
      {phase?.id && (
        <div className="Polaris-Card__Section">
          <p>
            <strong>{title} Total:</strong> ${phase[`${category}_sum`]}
          </p>
        </div>
      )}
    </Card>
  );
};

export default PhasePayments;
