import React, { Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import LogoWhite from '../../../assets/LogoWhite.png';
import MediumOrnament from '../../redesignedFrontend/common/MediumOrnament';
import ReadMoreArrow from '../../shared/components/ReadMoreArrow';

const useStyles = makeStyles((theme) =>
  createStyles({
    megaMenu: {
      position: 'absolute',
      width: '100%',
      backgroundColor: '#3A4254',
      display: 'none',
      zIndex: 1000,
      top: 0,
      left: 0,
      minHeight: '80vh',
      cursor: 'default',
    },
    megaMenuLogo: {
      height: '40px',
      margin: '10px 20px',
      lineHeight: '28px',
    },
    latestInsightsTitle: {
      color: '#fff', // Default color
      fontFamily: 'Crimson Text',
      fontSize: '28px',
      textDecoration: 'none',
      fontWeight: 700,
      textAlign: 'left',
    },
    megaMenuResourceContainer: {
      backgroundColor: 'white',
      borderRadius: 15,
      textAlign: 'left',
      height: 150,
      marginTop: 10,
    },
    megaMenuResourceTitle: {
      fontFamily: 'Crimson Text',
      fontSize: '20px',
      lineHeight: '22px',
      textDecoration: 'none',
      fontWeight: 700,
      textAlign: 'left',
    },
    megaMenuLink: {
      color: '#fff', // Default color
      fontFamily: 'Crimson Text',
      fontSize: '36px',
      textDecoration: 'none',
      fontWeight: 700,
      maxWidth: '250px',
      marginBottom: 25,
      display: 'block', // Makes the whole area clickable
      '&:hover': {
        color: '#F3C66B', // Color on hover
      },
    },
  }),
);

interface MegaMenuProps {
  megaMenuVisible: boolean;
  setMegaMenuVisible: Dispatch<SetStateAction<boolean>>;
  featuredResources: any[];
}

const MegaMenu: React.FC<MegaMenuProps> = (props) => {
  let classes = useStyles();

  let { megaMenuVisible, setMegaMenuVisible, featuredResources } = props;

  return (
    <div className={classes.megaMenu} style={{ display: 'block' }}>
      <Grid container style={{ maxWidth: 1400, margin: 'auto', padding: 30 }}>
        <Grid item xs={8}>
          <Grid container spacing={2} style={{ backgroundColor: '#545D78', height: '100%', borderRadius: 20 }}>
            <Grid item xs={12}>
              <div style={{ height: 50 }}>
                <img alt="logo" src={LogoWhite} className={classes.megaMenuLogo} style={{ float: 'left' }} />
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: 50 }}>
              <h3 className={classes.latestInsightsTitle}>Latest Insights</h3>
              {featuredResources?.slice(0, 3).map((resource) => (
                <Grid container item xs={12} key={resource.id} className={classes.megaMenuResourceContainer}>
                  <Grid item>
                    <img src={resource.s3_image_url} alt={resource.title} style={{ width: '150px', height: '150px' }} />
                  </Grid>
                  <Grid
                    item
                    style={{
                      paddingLeft: 20,
                      maxWidth: 'calc(100% - 180px)',
                      display: 'flex',
                      alignItems: 'center',
                      height: 150,
                    }}
                  >
                    <div>
                      <MediumOrnament text={resource.formatted_category_name} centerText centerComponent={false} />
                      <h3 className={classes.megaMenuResourceTitle}>{resource.title}</h3>
                      <ReadMoreArrow message="Read more" link={resource?.link} />
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ paddingTop: 100, paddingLeft: 20, textAlign: 'left', position: 'relative' }}>
          <IconButton
            onClick={() => {
              console.log('clock');
              setMegaMenuVisible(false);
            }}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              color: 'black',
              margin: '10px',
              zIndex: 1200,
              backgroundColor: 'transparent',
            }}
          >
            <Typography
              variant="body1"
              style={{
                marginLeft: '35px',
                userSelect: 'none',
                fontSize: '24px',
                fontFamily: 'Crimson Text',
                color: '#fff',
                fontWeight: 700,
              }}
            >
              Close
            </Typography>
            <CloseIcon style={{ fontSize: '24px', color: '#fff', marginLeft: 5 }} />
          </IconButton>
          <ul style={{ listStyleType: 'none' }}>
            <li style={{ maxWidth: '250px' }}>
              <a href="resources/blog-post" className={classes.megaMenuLink}>
                Articles
              </a>
            </li>
            <li style={{ maxWidth: '250px' }}>
              <a href="resources/case-study" className={classes.megaMenuLink}>
                Case Studies
              </a>
            </li>
            <li style={{ maxWidth: '250px' }}>
              <a href="/resources/event" className={classes.megaMenuLink}>
                Events
              </a>
            </li>
            <li>
              <a href="/frequently-asked-questions" className={classes.megaMenuLink}>
                Got a Question?
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default MegaMenu;
