import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Button } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import ReactQuill from 'react-quill';
import axios from '../../utils/axios.utils';
import FormInput from '../../form/components/FormInput';
import { Opportunity, OpportunityKeyDetail } from '../model';
import siteWideStyles from '../../styles/siteWideStyles';

interface KeyDetailsComboBoxProps {
  opportunity: Opportunity;
  setOpportunity: Dispatch<SetStateAction<Opportunity>>;
  keyDetail: OpportunityKeyDetail;
  index: number;
  setChooseIconModalOpen: Dispatch<SetStateAction<boolean>>;
  setKeyDetailIndexToEdit: Dispatch<SetStateAction<number>>;
}

const KeyDetailsComboBox: React.FC<KeyDetailsComboBoxProps> = (props) => {
  let { opportunity, setOpportunity, keyDetail, index, setChooseIconModalOpen, setKeyDetailIndexToEdit } = props;

  let handleIconClicked = (index: number) => {
    setKeyDetailIndexToEdit(index);
    setChooseIconModalOpen(true);
  };

  let handleContentChanged = (value: string) => {
    let keyDetails = [...opportunity.opportunity_key_details];
    keyDetails[index].content = value;

    let updatedOpportunity = { ...opportunity, opportunity_key_details: keyDetails };
    setOpportunity(updatedOpportunity);
  };

  let requestToDeleteExistingKeyDetail = async () => {
    try {
      let response = await axios.delete(`case_studies/${keyDetail.id}/delete_key_detail`);
      let updatedOpportunity = response.data.result;
      setOpportunity(updatedOpportunity);
    } catch (error: any) {
      console.log(error);
    }
  };

  let handleDecreasePositionIndex = () => {
    let keyDetails = [...opportunity.opportunity_key_details];
    let selectedKeyDetail = keyDetails[index];
    let previousKeyDetail = keyDetails[index - 1];

    keyDetails[index].position_index -= 1;
    keyDetails[index - 1].position_index += 1;

    keyDetails[index] = previousKeyDetail;
    keyDetails[index - 1] = selectedKeyDetail;

    let updatedOpportunity = { ...opportunity, opportunity_key_details: keyDetails };
    setOpportunity(updatedOpportunity);
  };

  let removeNewKeyDetail = () => {
    let keyDetails = [...opportunity.opportunity_key_details];
    keyDetails.splice(index, 1);

    let updatedOpportunity = { ...opportunity, opportunity_key_details: keyDetails };
    setOpportunity(updatedOpportunity);
  };

  let handleDeleteKeyDetail = async () => {
    if (keyDetail.id) {
      requestToDeleteExistingKeyDetail();
    } else {
      removeNewKeyDetail();
    }
  };

  return (
    <Grid container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <p>{keyDetail.position_index + 1}</p>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        {keyDetail.s3_image_url ? (
          <button
            type="button"
            style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
            onClick={() => handleIconClicked(index)}
          >
            <img
              src={keyDetail.s3_image_url}
              alt="Icon"
              style={{ width: '50px', height: '50px', marginLeft: '8px', cursor: 'pointer' }}
            />
          </button>
        ) : (
          <Button variant="contained" onClick={() => handleIconClicked(index)}>
            Select Icon
          </Button>
        )}
      </Grid>
      <Grid item xs={6} style={{ marginRight: 10 }}>
        <ReactQuill theme="snow" value={keyDetail.content} onChange={(value) => handleContentChanged(value)} />
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={siteWideStyles.secondaryButton} onClick={handleDecreasePositionIndex}>
          <ArrowUpward />
        </Button>
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={siteWideStyles.destructiveButton} onClick={handleDeleteKeyDetail}>
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default KeyDetailsComboBox;
