import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

import PaymentForm from '../components/PaymentForm';
import RecipientNameLine from '../components/RecipientNameLine';

import { PaymentsContext } from '../contexts/PaymentsContext';
import PaymentConfirmationCard from '../components/PaymentConfirmationCard';
import InformationField from '../../form/components/InformationField';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import siteWideStyles from '../../styles/siteWideStyles';

const EditPaymentPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { paymentId } = useParams<Record<string, string>>();

  const [formValues, setFormValues] = useState<any>({});
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(false);

  const { payment, fetchPayment, paymentsLoading, fetchFormOptions, updatePayment, formError, paymentSubmitting } =
    useContext(PaymentsContext);

  useEffect(() => {
    fetchPayment(paymentId);
    fetchFormOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormValues(payment);
  }, [payment]);

  const shouldDisableUpdateButton = () => {
    if (!formValues?.category) return true;
    if (!formValues?.status) return true;
    return false;
  };

  useEffect(() => {
    setUpdateButtonDisabled(shouldDisableUpdateButton());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    // <Grid container style={{ padding: '1% 2%' }}>
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={payment?.description}
        badge={{ label: payment?.status }}
        backButton={{ label: 'All Payments', link: '/admin/payments' }}
        primaryButton={{ label: 'View Insighter Profile', link: `/admin/insighters/${payment?.insighter_id}` }}
      />

      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <RecipientNameLine user={payment?.user} />
        <InformationField label="Creation Method" value={payment?.creation_method} />
        &emsp; &emsp;
        <InformationField label="Created By" value={payment?.created_by} />
      </Grid>
      <Grid item xs={12}>
        <PaymentForm formValues={formValues} setFormValues={setFormValues} user={payment?.user} />
      </Grid>
      {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <Button
          style={siteWideStyles.primaryButton}
          onClick={() => updatePayment(payment?.id, formValues)}
          disabled={updateButtonDisabled}
        >
          Update Payment
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        {payment?.payment_confirmations?.length > 0 ? (
          <>
            {payment.payment_confirmations?.map((confirmation) => (
              <PaymentConfirmationCard key={confirmation.id} confirmation={confirmation} />
            ))}
          </>
        ) : (
          <Card style={{ padding: 20 }}>
            <Typography variant="h4">No payment confirmations available</Typography>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default EditPaymentPage;
