import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@shopify/polaris';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Opportunity } from '../../opportunities/model';
import { Project } from '../model';

export const opportunityColumns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
  },
  {
    name: 'Condition',
    selector: 'diagnosis',
    sortable: true,
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
];

interface ProjectOpportunitiesProps {
  project: Project;
  opportunities: Opportunity[];
}

const ProjectOpportunities: React.FC<ProjectOpportunitiesProps> = (props) => {
  const { project, opportunities } = props;
  const history = useHistory();

  return (
    <Card sectioned>
      <div style={{ marginBottom: 15 }}>
        <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
          <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
            <h2 className="Polaris-Heading">Opportunities</h2>
          </div>
          <div className="Polaris-Stack__Item">
            <div className="Polaris-ButtonGroup">
              <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                <Button primary url={`/admin/projects/${project.id}/opportunities/new`}>
                  Add New Opportunity
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        onRowClicked={(row: Record<string, string>) => history.push(`/admin/opportunities/${row.id}`)}
        columns={opportunityColumns}
        data={opportunities}
        pagination
        striped
        highlightOnHover
        pointerOnHover
        noHeader
      />
    </Card>
  );
};

export default ProjectOpportunities;
