import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import Page from '../../shared/components/Page';
import { CaseStudy } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import CaseStudyTextForm from '../components/CaseStudyTextForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditCaseStudyPage: React.FC = () => {
  const { caseStudyId } = useParams<{ caseStudyId: string }>();
  const history = useHistory();

  const [formValues, setFormValues] = useState<CaseStudy>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const editCaseStudy = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      console.log(key, value);
      combinedFormData.append(`case_study[${key}]`, value);
    });

    try {
      await axios.put(`case_studies/${caseStudyId}`, combinedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      history.push(`/admin/case-studies/${caseStudyId}/edit-images`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  const fetchCaseStudy = async () => {
    try {
      let response = await axios.get(`case_studies/${caseStudyId}`);
      let formValuesToSet = { ...response?.data?.result };
      if (!formValuesToSet.ornament_text) {
        formValuesToSet.ornament_text = 'Your Insights in Action';
      }
      setFormValues(formValuesToSet);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (caseStudyId) {
      fetchCaseStudy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStudyId]);

  return (
    <>
      <Page fullWidth>
        <Grid container>
          <Grid item xs={12}>
            <AdminPageHeader
              title="Edit Case Study"
              backButton={{ label: 'Case Studies', link: '/admin/case-studies' }}
            />
          </Grid>
          <Grid item xs={12}>
            <CaseStudyTextForm
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formErrors}
              setImageFileData={setImageFileData}
            />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => editCaseStudy()} style={siteWideStyles.primaryButton}>
              Update
            </Button>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default EditCaseStudyPage;
