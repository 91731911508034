import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { User } from '../model';

interface UserContextInterface {
  isLoading?: boolean;
  user: User;
  setUser?: Dispatch<SetStateAction<User>>;

  login?: (email: string, password: string) => Promise<void>;
  updatePassword?: (password: string, confirmation_token?: string) => Promise<void>;
  updateProfilePassword?: (password: string) => Promise<void>;

  resetPassword?: (email: string) => Promise<void>;
  logout?: () => Promise<void>;
  currentUserHasRole?: (role: string) => boolean;
  loginFormError?: string;
  setLoginError?: Dispatch<SetStateAction<string>>;
  formErrors?: Record<string, string>;
  passwordResetError?: string;
}

const UserContext = React.createContext<UserContextInterface>({ user: {} });

const UserContextConsumer = UserContext.Consumer;
const UserContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState<User>(undefined);
  const [loginFormError, setLoginError] = useState<string>(undefined);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [passwordResetError, setPasswordResetError] = useState<string>(undefined);

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      await axios
        .post(`sessions`, { email, password })
        .then((response) => {
          if (response.data.jwt) {
            localStorage.setItem('token', response.data.jwt);
            setUser(response.data.user);
            if (response.data.user.role === 'admin') {
              if (window.innerWidth < 500) {
                history.push('/admin/interview-schedule');
              } else {
                history.push('/admin/client-projects');
              }
            } else {
              history.push('/opportunities');
            }
          } else {
            history.push('/confirm-email');
          }
        })
        .catch((error) => {
          setLoading(false);
          setLoginError(error.response.data.error);
        });
    } catch {
      setUser({});
      setLoading(false);
      setLoginError('Failed to connect to server. Please check your internet connection and reload the page.');
    }
  };

  const resetPassword = async (email: string) => {
    try {
      setLoading(true);
      await axios
        .post(`passwords`, { email })
        .then(() => {
          history.push('/password-reset/sent');
        })
        .catch((error) => {
          history.push('/password-reset/sent');
          setLoading(false);
        });
    } catch {
      setPasswordResetError('Something went wrong, please reload the page and try again.');
      setLoading(false);
    }
  };

  // This function is used when a user changes their password without being logged-in.  Linked from "Forgot your Password?"
  const updatePassword = async (password: string, confirmation_token = '0') => {
    try {
      await axios.put(`passwords/${confirmation_token}`, { password, confirmation_token }).then((response) => {
        if (response.data.error) {
          setLoginError(response.data.error);
        } else if (response.data.jwt) {
          localStorage.setItem('token', response.data.jwt);
          setUser(response.data.user);
          if (response.data.user.role === 'admin') {
            history.push('/admin/client-projects');
          } else {
            history.push('/opportunities');
          }
        }
      });
    } catch (error: any) {
      setFormErrors({
        password: error.response.data.error.password,
      });
    }
  };

  // This function is used when an Insighter changes their password from their profile.
  const updateProfilePassword = async (password: string) => {
    try {
      await axios.post(`insighter_passwords`, { password }).then((response) => {
        if (response.data.error) {
          setLoginError(response.data.error);
        } else if (response.data.jwt) {
          localStorage.setItem('token', response.data.jwt);
          setUser(response.data.user);
          if (response.data.user.role === 'admin') {
            history.push('/admin/client-projects');
          } else {
            history.push('/opportunities');
          }
        }
      });
    } catch (error: any) {
      setFormErrors({
        password: error.response.data.error.password,
      });
    }
  };

  const logout = async () => {
    try {
      localStorage.setItem('token', '');
      setUser({});
    } catch {
      setUser({});
    }
  };

  const currentUserHasRole = (role: string) => {
    if (user?.roles?.find((user_role: string) => user_role === role)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        const result = await axios.get(`sessions`);
        setUser(result.data.user || {});
      } catch (error: any) {
        setUser({});
      }
    }

    if (user === undefined) {
      fetchUser();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        user,
        setUser,

        login,
        updatePassword,
        updateProfilePassword,
        resetPassword,
        logout,
        currentUserHasRole,
        loginFormError,
        setLoginError,
        formErrors,
        passwordResetError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContextConsumer, UserContext };
