import React from 'react';
import { Loading } from '@shopify/polaris';
import ProfileForm from '../components/ProfileForm';
import AdminProfileForm from '../components/AdminProfileForm';
import { RegistrationsContext } from '../contexts/RegistrationsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import FormSkeleton from '../../loading/components/FormSkeleton';

const EditProfile: React.FunctionComponent = () => {
  const { profile, fetchProfile, fetchFormOptions, profileLoading } = React.useContext(RegistrationsContext);
  const { user } = React.useContext(UserContext);

  const [profileFetched, setProfileFetched] = React.useState(false);

  React.useEffect(() => {
    if (!profileFetched) {
      fetchProfile(user.id);
      fetchFormOptions();
      setProfileFetched(true);
    }
  }, [profileFetched, fetchProfile, user, fetchFormOptions]);

  return profileLoading ? (
    <>
      <Loading />
      <FormSkeleton />
    </>
  ) : (
    <>
      {user?.role?.toLowerCase() === 'admin' ? (
        <AdminProfileForm profile={profile} />
      ) : (
        <ProfileForm profile={profile} />
      )}
    </>
  );
};

export default EditProfile;
