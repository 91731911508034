import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Card, Button } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import InformationField from '../../form/components/InformationField';
import siteWideStyles from '../../styles/siteWideStyles';

import DeleteEventResourceConfirmModal from '../components/DeleteEventResourceConfirmModal';
import EventViewer from '../components/EventViewer';

import { EventResource } from '../model';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EventResourceDetailPage: React.FC = () => {
  const { eventResourceId } = useParams<{ eventResourceId: string }>();
  const history = useHistory();

  const [eventResource, setEventResource] = useState<EventResource>();
  const [deleteEventResourceConfirmModalOpen, setDeleteEventResourceConfirmModalOpen] = useState<boolean>(false);

  const fetchEventResource = async () => {
    try {
      const response = await axios.get(`event_resources/${eventResourceId}`);
      setEventResource(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEventResource();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <DeleteEventResourceConfirmModal
        eventResourceToDelete={eventResourceId}
        deleteEventResourceConfirmModalOpen={deleteEventResourceConfirmModalOpen}
        setDeleteEventResourceConfirmModalOpen={setDeleteEventResourceConfirmModalOpen}
      />
      <AdminPageHeader title={eventResource?.title} backButton={{ label: 'Events', link: '/admin/event-resources' }} />

      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item>
              <InformationField label="Show on Event" value={eventResource?.status} />
            </Grid>
            <Grid item>
              <InformationField label="Description" value={eventResource?.thumbnail_description} />
            </Grid>
            <Grid item>
              <InformationField label="Link" value={eventResource?.link} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 30 }}>
        <h1 className="Polaris-Header-Title">Preview</h1>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
        <EventViewer eventResource={eventResource} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Button
          style={{ ...siteWideStyles.primaryButton, marginRight: 8 }}
          onClick={() => history.push(`/admin/event-resources/${eventResourceId}/edit`)}
        >
          Edit Event
        </Button>
        <Button style={siteWideStyles.destructiveButton} onClick={() => setDeleteEventResourceConfirmModalOpen(true)}>
          Delete Event
        </Button>
      </Grid>
    </Grid>
  );
};

export default EventResourceDetailPage;
