import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { TeamBio } from '../model';
import axios from '../../utils/axios.utils';
import TeamBioForm from '../components/TeamBioForm';
import siteWideStyles from '../../styles/siteWideStyles';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const CreateTeamBioPage: React.FC = () => {
  const history = useHistory();

  const [formValues, setFormValues] = useState<TeamBio>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const createTeamBio = async () => {
    try {
      let response = await axios.post('team_bios', formValues);
      if (response?.statusText === 'OK') {
        let newId = response?.data?.result?.id;
        history.push(`/admin/team-bios/${newId}/edit`);
      }
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={3}>
      <AdminPageHeader title="Create Team Bio" backButton={{ label: 'Team Bios', link: '/admin/team-bios' }} />
      <Grid item xs={12}>
        <TeamBioForm formValues={formValues} setFormValues={setFormValues} formErrors={formErrors} />
      </Grid>
      <Grid item xs={3}>
        <Button onClick={() => createTeamBio()} style={siteWideStyles.primaryButton}>
          Create Team Bio
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateTeamBioPage;
