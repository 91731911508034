import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { Icon } from '../model';
import axios from '../../utils/axios.utils';
import IconForm from '../components/IconForm';
import siteWideStyles from '../../styles/siteWideStyles';
import Page from '../../shared/components/Page';
import BackButton from '../../navigation/components/BackButton';

const CreateIconPage: React.FC = () => {
  const history = useHistory();

  const [formValues, setFormValues] = useState<Icon>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const createIcon = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`icon[${key}]`, value);
    });
    console.log('combinedFormData', combinedFormData);
    try {
      await axios.post('icons', combinedFormData);
      history.push(`/admin/icons`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  return (
    <>
      <BackButton pageAction={false} url="/admin/icons" />
      <Page fullWidth title="New Icon">
        <Grid container>
          <Grid item xs={12}>
            <IconForm
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formErrors}
              setImageFileData={setImageFileData}
            />
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => createIcon()} style={siteWideStyles.primaryButton}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default CreateIconPage;
