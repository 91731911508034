import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EventViewer from '../../../eventResources/components/EventViewer';
import { Opportunity } from '../../../opportunities/model';

interface OpportunitiesGalleryProps {
  redesignOpportunities: any[];
}

// Convert the Opportunity object to use the keys of the EventResource interface
const opportunityIntoEventResource = (opportunity: Opportunity) => {
  return {
    id: opportunity.id,
    title: opportunity.name,
    thumbnail_description: opportunity.thumbnail_description || opportunity.name,
    link: `/opportunities/${opportunity.id}`,
    s3_image_url: opportunity.thumbnail_image_url,
    status: opportunity.status,
    created_at: opportunity.created_at,
    featured: 'true',
    show_on_homepage: 'true',
    arrow_message: 'Read More',
  };
};

const OpportunitiesGallery: React.FC<OpportunitiesGalleryProps> = (props) => {
  const { redesignOpportunities } = props;
  const history = useHistory();

  return (
    <Grid container spacing={4} style={{ maxWidth: 1600, margin: 'auto' }}>
      {redesignOpportunities?.map((opportunity) => (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          key={opportunity?.id}
          style={{ marginBottom: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
          onClick={() => history.push(`/opportunities/${opportunity.id}`)}
        >
          <EventViewer eventResource={opportunityIntoEventResource(opportunity)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OpportunitiesGallery;
