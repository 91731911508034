import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Page, Button } from '@shopify/polaris';
import { Grid } from '@mui/material';
import { CSVLink } from 'react-csv';
import PaymentsList from '../components/PaymentsList';
import PaymentFilterBar from '../components/PaymentFilterBar';
import { PaymentsContext } from '../contexts/PaymentsContext';
import todaysDate from '../../utils/dateFunctions';

const PaymentsListContainer: React.FunctionComponent = () => {
  const history = useHistory();
  const { paymentsLoading, paymentsDownload } = React.useContext(PaymentsContext);

  return (
    <Page fullWidth>
      <Grid container justifyContent="space-between">
        <Grid item>
          <h1 className="Polaris-Header-Title">All Payments</h1>
        </Grid>
        <Grid item>
          {paymentsDownload && (
            <CSVLink
              data={paymentsDownload}
              filename={`PAYMENTS-${todaysDate()}.csv`}
              style={{ textDecoration: 'none' }}
            >
              <Button primary>Download Payments</Button>
            </CSVLink>
          )}
          <span style={{ marginLeft: 7 }} />
          <Button primary onClick={() => history.push(`/admin/payments/new`)}>
            Create Payment
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <PaymentFilterBar />
        <PaymentsList />
      </Card>
    </Page>
  );
};

export default PaymentsListContainer;
