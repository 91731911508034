import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Button, Card, Layout, Modal, Page, TextContainer } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import PhasePayments from '../components/PhasePayments';
import LeadGroupsList from '../../leadGroups/components/LeadGroupsList';
import PhaseRespondents from '../components/PhaseRespondents';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import { UserContext } from '../../auth/contexts/UserContext';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';
import axios from '../../utils/axios.utils';

const PhaseDetail: React.FunctionComponent = () => {
  const { phaseId } = useParams<Record<string, string>>();
  const { phase, phaseAddress, fetchPhase, phaseLoading, updatePhase, archivePhase, phaseSubmitting } =
    React.useContext(ProjectPhasesContext);
  const { currentUserHasRole } = React.useContext(UserContext);

  const [phaseFetched, setPhaseFetched] = React.useState(false);
  const [showArchiveModal, setShowArchiveModal] = React.useState(false);

  const colorByStatus = {
    active: 'green',
    inactive: 'red',
    upcoming: 'orange',
  };

  React.useEffect(() => {
    if (!phaseFetched) {
      fetchPhase(phaseId);
      setPhaseFetched(true);
    }
  }, [phaseFetched, fetchPhase, phaseId]);

  const fetchQualtricsStatus = async () => {
    axios.get(`phases/${phaseId}/qualtrics_status`).then((response) => {
      fetchPhase(phaseId);
    });
  };

  const statusBadge = () => {
    if (phase?.status === 'published' || phase?.status === 'active') {
      return <Badge status="success">Published</Badge>;
    }
    if (phase?.status === 'draft') {
      return <Badge status="warning">Draft</Badge>;
    }
    if (phase?.status === 'archived') {
      return <Badge status="critical">Archived</Badge>;
    }
    return <Badge status="critical">Inactive</Badge>;
  };

  const secondaryActions = [
    {
      content: 'Duplicate Phase',
      accessibilityLabel: 'Duplicate Phase Button',
      url: `/admin/phases/${phase?.id}/duplicate`,
    },
  ];

  if (phase?.status === 'draft' || phase?.status === 'published') {
    secondaryActions.unshift({
      content: 'Phase Launch Checklist',
      accessibilityLabel: 'Phase Launch Checklist Button',
      url: `/admin/phases/${phase?.id}/launch-checklist`,
    });
  }

  const ProjectButton = () => {
    if (phase?.project_id) {
      return (
        <Grid style={{ marginLeft: '30px', marginTop: '15px' }}>
          <Button url={`/admin/projects/${phase?.project_id}`}>Project {phase?.project_number}</Button>
        </Grid>
      );
    }
    return null;
  };

  return phaseLoading ? (
    <DetailPageSkeleton />
  ) : (
    <>
      <ProjectButton />
      <Page
        fullWidth
        title={`${phase?.name} for ${phase?.project_number}`}
        titleMetadata={statusBadge()}
        primaryAction={{
          content: 'Edit Phase',
          accessibilityLabel: 'Edit Phase Button',
          url: `/admin/phases/${phase?.id}/edit`,
        }}
        secondaryActions={secondaryActions}
        additionalMetadata={`Created on ${phase?.created_at}`}
      >
        <Layout>
          <Layout.Section>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Card sectioned title="Phase Details">
                  <Grid container>
                    <Grid item xs={3}>
                      <Card.Section title="name">
                        <p>{phase?.name}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="Project Number">
                        <p>
                          <a href={`/admin/projects/${phase?.project_id}`}>{phase?.project_number}</a>
                        </p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="start date">
                        <p>{phase?.start_date_human}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="end date">
                        <p>{phase?.end_date_human}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="Honorarium Fee">
                        <p>$ {phase?.honorarium_fee}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="Travel Amount">
                        <p>$ {phase?.travel_amount}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="Homework">
                        <p>{phase?.homework ? 'Yes' : 'No'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="status in TI">
                        <p
                          style={{
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            fontSize: 18,
                            color: colorByStatus[phase?.status],
                          }}
                        >
                          {phase?.status}
                        </p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="Status in Qualtrics">
                        <p
                          style={{
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            fontSize: 18,
                            color: colorByStatus[phase?.active_in_qualtrics],
                            marginBottom: 5,
                          }}
                        >
                          {phase?.active_in_qualtrics}
                        </p>
                        <Button onClick={fetchQualtricsStatus}>Update</Button>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="Automatically Update Status">
                        <p>{phase?.update_status ? 'Yes' : 'No'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6}>
                      <Card.Section title="Location">
                        {phase?.location === 'in_person' ? (
                          <>
                            <p>In-Person</p>
                            <p>{phaseAddress?.address_line1}</p>
                            <p>
                              {phaseAddress?.city}, {phaseAddress?.state} {phaseAddress?.zip}
                            </p>
                          </>
                        ) : (
                          <p>{phase?.location}</p>
                        )}
                      </Card.Section>
                    </Grid>
                    <Grid item xs={3}>
                      <Card.Section title="Tremendous Campaign">
                        <p>{phase?.tremendous_campaign_name}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="Additional Respondent Criteria">
                        {/* eslint-disable-next-line */}
                        <div dangerouslySetInnerHTML={{ __html: phase?.respondent_criteria }} />
                      </Card.Section>
                    </Grid>
                  </Grid>
                </Card>
                <Card sectioned title="Qualtrics & Calendly Details">
                  <Grid container>
                    <Grid item xs={4}>
                      <Card.Section title="qualtrics survey id">
                        <p>{phase?.qualtrics_survey_id}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={4}>
                      <Card.Section title="respondent quota">
                        <p>{phase?.respondent_quota}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={4}>
                      <Card.Section title="Automatically Import Respondents">
                        <p>{phase?.import_respondents ? 'Yes' : 'No'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={4}>
                      <Card.Section title="Automatic Import Role Filters">
                        <p>{phase?.insighter_role_names}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="calendly event">
                        <p>{phase?.calendly_event_type_name}</p>
                      </Card.Section>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card title="Additional Phase Information">
                  <Grid container>
                    <Grid item xs={12}>
                      <Card.Section title="Interview Category">
                        <p>{phase?.interview_category}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="Insighter Category">
                        <p>{phase?.insighter_category}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="Recruiter">
                        <p>{phase?.recruiter}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="TI Recruiter">
                        <p>{phase?.ti_recruiter}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="Moderators">
                        <p>{phase?.moderator}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="Survey Responses">
                        <p>{`${phase?.total_survey_responses} Total Applications`}</p>
                        <p>{`${phase?.complete_survey_responses} Completed Applications`}</p>
                        <p>{`${phase?.incomplete_survey_responses} Incomplete Applications`}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="Scheduled">
                        <p>{`${phase?.scheduled_interview_count} Interviews`}</p>
                        <p>{`${phase?.scheduled_respondents_count} Respondents`}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12}>
                      <Card.Section title="Completed">
                        <p>{`${phase?.completed_interview_count} Interviews`}</p>
                        <p>{`${phase?.completed_respondents_count} Respondents`}</p>
                      </Card.Section>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <br />
            <PhaseRespondents phase={phase} />
            <br />
            <LeadGroupsList phaseId={phaseId} phase={phase} />
            <br />
            <PhasePayments phase={phase} payments={phase?.payments} title="Payment" category="payments" />
            <br />
            <PhasePayments phase={phase} payments={phase?.expenses} title="Expense" category="expenses" />
            <br />
            {currentUserHasRole('superadmin') && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {phase?.status === 'archived' ? (
                      <Grid container>
                        <Grid item xs={6}>
                          <Button
                            loading={phaseSubmitting}
                            onClick={() => {
                              updatePhase(phase?.id, { status: 'inactive' });
                            }}
                          >
                            Unarchive Phase
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Modal
                          instant
                          open={showArchiveModal}
                          onClose={() => setShowArchiveModal(false)}
                          title="Archive Confirmation"
                          primaryAction={{
                            content: 'Archive Phase',
                            destructive: true,
                            onAction: () => archivePhase(phase?.id),
                          }}
                          secondaryActions={[
                            {
                              content: 'Cancel',
                              onAction: () => setShowArchiveModal(false),
                            },
                          ]}
                        >
                          <Modal.Section>
                            <TextContainer>
                              <p>Are you sure you want to archive this phase? This will hide the phase by default.</p>
                            </TextContainer>
                          </Modal.Section>
                        </Modal>
                        <Grid item xs={6}>
                          <Button
                            destructive
                            loading={phaseSubmitting}
                            onClick={() => {
                              setShowArchiveModal(true);
                            }}
                          >
                            Archive Phase
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <br />
              </>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default PhaseDetail;
