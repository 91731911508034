import React, { useContext } from 'react';
import { Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import { PaymentsContext } from '../contexts/PaymentsContext';
import BulkPaymentContainer from '../components/BulkPaymentContainer';
import NewPaymentConfirmation from '../components/NewPaymentConfirmation';

const BulkPaymentPage: React.FunctionComponent = () => {
  const { payment } = useContext(PaymentsContext);

  return (
    <Page fullWidth title="Create Bulk Payments">
      <br />
      <Layout>
        <Layout.Section>{payment?.id ? <NewPaymentConfirmation /> : <BulkPaymentContainer />}</Layout.Section>
        <Layout.Section secondary />
      </Layout>
    </Page>
  );
};

export default BulkPaymentPage;
