/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, Button } from '@shopify/polaris';
import { Grid } from '@mui/material';
import SelectInput from '../../form/components/SelectInput';
import { PaymentFormValues } from '../model';
import FormInput from '../../form/components/FormInput';
import { PaymentsContext } from '../contexts/PaymentsContext';

import axios from '../../utils/axios.utils';

type Props = Record<string, unknown>;

const NewExpenseForm: React.FC<Props> = (props) => {
  const { fetchFormOptions, formOptions, setPayment } = useContext(PaymentsContext);
  const [formValues, setFormValues] = useState<PaymentFormValues>({});
  const [phaseOptions, setPhaseOptions] = useState<any[]>([]);
  const [formError, setFormError] = useState<string>('');
  const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
  const [disableCreateButton, setDisableCreateButton] = useState<boolean>(true);
  const [formOptionsFetched, setFormOptionsFetched] = useState<boolean>(false);

  const { search } = useLocation();
  const { projectId = '' } = useParams<Record<string, string>>();
  let { phaseId = '' } = useParams<Record<string, string>>();
  if (!phaseId) phaseId = new URLSearchParams(search).get('phase_id') || '';

  useEffect(() => {
    if (!formOptionsFetched) {
      fetchFormOptions();
      setFormOptionsFetched(true);

      axios.get(`payments/new?project_id=${projectId}`).then((response) => {
        setPhaseOptions(response?.data?.phase_options);
        const newFormValues = {
          ...formValues,
          project_id: projectId,
          phase_id: phaseId,
        };
        setFormValues(newFormValues);
      });
    }
  }, [formOptionsFetched, fetchFormOptions, projectId, phaseId]);

  const shouldDisableCreateButton = () => {
    if (!formValues?.category) return true;
    if (!formValues?.status) return true;
    return false;
  };

  useEffect(() => {
    setDisableCreateButton(shouldDisableCreateButton());
  }, [formValues]);

  const handleProjectChange = async (value: string) => {
    await axios.get<string, any>(`payments/new?project_id=${value}`).then((response) => {
      setPhaseOptions(response?.data?.phase_options);
      setFormValues({ ...formValues, project_id: value });
    });
  };

  const handleCreate = async () => {
    try {
      setPaymentSubmitting(true);
      axios
        .post<string, any>(`payments.json`, {
          payment: { ...formValues },
        })
        .then((response) => {
          setPayment(response.data.result);
        })
        .catch((error) => {
          setFormError(error.response.data.error);
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  return (
    <Card>
      <Grid container spacing={3} style={{ padding: '20px' }}>
        <Grid item xs={12}>
          <SelectInput
            label="Category"
            choices={[
              { label: 'Ad', value: 'ad' },
              { label: 'Other Expense', value: 'expense' },
            ]}
            onChange={(value) => {
              setFormValues({ ...formValues, category: value });
            }}
            value={formValues?.category}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            label="Description"
            value={formValues?.description}
            onChange={(value) => setFormValues({ ...formValues, description: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Amount"
            value={formValues?.number_amount}
            type="money"
            onChange={(value) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setFormValues({ ...formValues, number_amount: formattedValue });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Project (Optional)"
            choices={formOptions?.project_options}
            onChange={handleProjectChange}
            value={formValues?.project_id}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Phase (Optional)"
            choices={phaseOptions}
            onChange={(value) => setFormValues({ ...formValues, phase_id: value })}
            value={formValues?.phase_id}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Hold', value: 'hold' },
              { label: 'Incomplete', value: 'incomplete' },
              { label: 'Complete', value: 'complete' },
            ]}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
            value={formValues?.status}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Notes"
            multiline
            rows={4}
            onChange={(value) => setFormValues({ ...formValues, notes: value })}
            value={formValues?.notes}
          />
        </Grid>
      </Grid>
      {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
      <Grid item xs={12} style={{ padding: '20px' }}>
        <Button primary onClick={handleCreate} loading={paymentSubmitting} disabled={disableCreateButton}>
          Create Expense
        </Button>
      </Grid>
    </Card>
  );
};

export default NewExpenseForm;
