import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { Topic } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import TopicForm from '../components/TopicForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditTopicPage: React.FC = () => {
  const { topicId } = useParams<{ topicId: string }>();
  const history = useHistory();

  const [formValues, setFormValues] = useState<Topic>();
  const [formErrors, setFormErrors] = useState<string>('');

  const updateTopic = async () => {
    try {
      await axios.put<string, any>(`topics/${topicId}`, { topic: { ...formValues } });
      history.push(`/admin/topics`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (topicId) {
      axios.get(`topics/${topicId}`).then((response) => {
        setFormValues(response?.data?.result);
      });
    }
  }, [topicId]);

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={3}>
      <AdminPageHeader title={formValues?.name} backButton={{ link: '/admin/topics', label: 'Topics' }} />
      <Grid item xs={12}>
        <TopicForm formValues={formValues} setFormValues={setFormValues} formErrors={formErrors} />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => updateTopic()} style={siteWideStyles.primaryButton}>
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditTopicPage;
