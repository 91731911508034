import React, { useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import SelectInput from '../../form/components/SelectInput';
import { InsighterFinderContext } from '../contexts/InsighterFinderContext';
import siteWideStyles from '../../styles/siteWideStyles';

const PhasePicker: React.FC = () => {
  const {
    formOptions,
    selectedProject,
    handleProjectChange,
    phaseOptions,
    handlePhaseChange,
    selectedPhase,
    showPhasePicker,
    setShowPhasePicker,
  } = useContext(InsighterFinderContext);

  return (
    <Grid>
      {showPhasePicker ? (
        <Grid container spacing={3}>
          <Grid item xs={2} style={{ marginTop: 'auto', marginBottom: '10px' }}>
            <Button
              variant="contained"
              style={siteWideStyles.secondaryButton}
              onClick={() => setShowPhasePicker(false)}
            >
              Hide Phase Picker
            </Button>
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              label="Project"
              choices={formOptions?.project_options}
              onChange={handleProjectChange}
              value={selectedProject}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Phase"
              choices={phaseOptions?.phase_options}
              onChange={handlePhaseChange}
              value={selectedPhase}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={2} style={{ marginLeft: '25px', marginTop: 'auto', marginBottom: '10px' }}>
          <Button variant="contained" style={siteWideStyles.secondaryButton} onClick={() => setShowPhasePicker(true)}>
            Show Phase Picker
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PhasePicker;
