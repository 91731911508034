import React from 'react';
import { Card } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import Page from '../../shared/components/Page';
import DataTable from '../../dataTable/DataTable/DataTable';
import PhaseFilterBar from '../components/PhaseFilterBar';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import phaseColumns from '../constants/phaseColumns';

const PhasesList: React.FunctionComponent = () => {
  const { phases, fetchPhases, phaseLoading } = React.useContext(ProjectPhasesContext);
  const [phasesFetched, setPhasesFetched] = React.useState(false);

  React.useEffect(() => {
    const phaseFilters = sessionStorage.getItem('phaseFilters');

    if (!phasesFetched) {
      if (phaseFilters) {
        fetchPhases(phaseFilters);
      } else {
        fetchPhases('');
      }
      setPhasesFetched(true);
    }
  }, [fetchPhases, phasesFetched]);

  const history = useHistory();

  return (
    <Page
      fullWidth
      title="Project Phases"
      primaryAction={{
        content: 'Create Phase',
        onAction: () => history.push('/admin/phases/new'),
      }}
    >
      <Card>
        <br />
        <PhaseFilterBar />
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/phases/${row.id}`)}
          columns={phaseColumns}
          data={phases}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
          progressPending={phaseLoading}
        />
      </Card>
    </Page>
  );
};

export default PhasesList;
