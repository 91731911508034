import React, { FunctionComponent, useCallback, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Button, Card, Loading, Modal, Stack, Toast, TextContainer } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import ProjectPhases from '../components/ProjectPhases';
import ProjectOpportunities from '../components/ProjectOpportunities';
import ProjectPayments from '../components/ProjectPayments';
import ProjectExpenses from '../components/ProjectExpenses';
import { ProjectsContext } from '../contexts/ProjectsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import ProjectTimeline from '../components/ProjectTimeline';
import TimelineEvent from '../components/TimelineEvent';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const ProjectDetail: FunctionComponent = () => {
  const [projectFetched, setProjectFetched] = useState(false);
  const { projectId } = useParams<Record<string, string>>();
  const { project, fetchProject, projectLoading, updateProject, archiveProject, projectSubmitting } =
    useContext(ProjectsContext);
  const { currentUserHasRole } = useContext(UserContext);

  useEffect(() => {
    if (!projectFetched) {
      fetchProject(projectId);
      setProjectFetched(true);
    }
  }, [projectFetched, fetchProject, projectId]);

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const loadingMarkup = projectLoading ? <Loading /> : null;
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const statusBadge = () => {
    if (project?.status === 'published') {
      return <Badge status="success">Published</Badge>;
    }
    if (project?.status === 'active') {
      return <Badge status="success">Active</Badge>;
    }
    if (project?.status === 'archived') {
      return <Badge status="critical">Archived</Badge>;
    }
    if (project?.status === 'inactive') {
      return <Badge status="critical">Inactive</Badge>;
    }
    return <Badge status="warning">Draft</Badge>;
  };

  const checkListLabel = () => {
    if (project.status === 'draft') {
      return [
        {
          label: 'Launch Checklist',
          link: `/admin/projects/${project?.id}/launch-checklist`,
        },
      ];
    }
    return [
      {
        label: 'Wrap-up Checklist',
        link: `/admin/projects/${project?.id}/wrap-up-checklist`,
      },
    ];
  };

  // ---- Page markup ----
  const actualPageMarkup = (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={project?.name}
        badge={{ label: project?.status }}
        primaryButton={{ label: 'Edit Project', link: `/admin/projects/${project?.id}/edit` }}
        secondaryButton={checkListLabel()[0]}
      />
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Card sectioned title="Project Details">
            <Grid container>
              <Grid item xs={3}>
                <Card.Section title="Project Name">
                  <p>{project?.name}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={3}>
                <Card.Section title="Project Number">
                  <p>{project?.project_number}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={3}>
                <Card.Section title="Overall Start Date">
                  <p>{project?.project_timeline?.overall_start_date_human}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={3}>
                <Card.Section title="Overall End Date">
                  <p>{project?.project_timeline?.overall_end_date_human}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Notes">
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{ __html: project?.notes }} />
                </Card.Section>
              </Grid>
            </Grid>
          </Card>
          <Card title="Project Timeline">
            <ProjectTimeline minEvents={4}>
              <TimelineEvent
                color={project?.project_timeline?.pre_recruit_status === 'Complete' ? 'green' : '#ccc'}
                title={`Pre-recruit (${project?.project_timeline?.pre_recruit_status})`}
                subtitle={project?.project_timeline?.pre_recruit_dates}
              />
              <TimelineEvent
                color={project?.project_timeline?.official_recruit_status === 'Complete' ? 'green' : '#ccc'}
                title={`Official Recruit (${project?.project_timeline?.official_recruit_status})`}
                subtitle={project?.project_timeline?.official_recruit_dates}
              />
              <TimelineEvent
                color={project?.project_timeline?.fielding_status === 'Complete' ? 'green' : '#ccc'}
                title={`Fielding (${project?.project_timeline?.fielding_status})`}
                subtitle={project?.project_timeline?.fielding_dates}
              />
              <TimelineEvent
                color={project?.project_timeline?.wrap_up_status === 'Complete' ? 'green' : '#ccc'}
                title={`Wrap-up (${project?.project_timeline?.wrap_up_status})`}
                subtitle={project?.project_timeline?.wrap_up_dates}
              />
            </ProjectTimeline>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ marginBottom: 15 }}>
          <Card title="Additional Project Information">
            <Grid container>
              <Grid item xs={12}>
                <Card.Section title="Client Name">
                  <p>{project?.client_name}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Project Manager">
                  <p>{project?.project_manager}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Secondary Project Manager">
                  <p>{project?.secondary_project_manager}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Recruiter">
                  <p>{project?.recruiter}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="TI Recruiter">
                  <p>{project?.ti_recruiter}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Moderators">
                  {project?.primary_moderator && <p>{project?.primary_moderator}</p>}
                  {project?.secondary_moderator && <p>{project?.secondary_moderator}</p>}
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Automatically Update Status">
                  <p>{project?.update_status ? 'Yes' : 'No'}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Conditions">
                  <Stack>
                    {project.conditions?.map((condition, index) => (
                      <Badge key={index}>{condition}</Badge>
                    ))}
                  </Stack>
                </Card.Section>
              </Grid>
              <Grid item xs={12}>
                <Card.Section title="Tags">
                  <Stack>
                    <Badge>None</Badge>
                  </Stack>
                </Card.Section>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <ProjectPhases project={project} phases={project.phases} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <ProjectOpportunities project={project} opportunities={project.opportunities} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <ProjectPayments project={project} payments={project.payments} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <ProjectExpenses project={project} expenses={project.expenses} />
      </Grid>
      {currentUserHasRole('superadmin') && (
        <>
          <Modal
            instant
            open={showArchiveModal}
            onClose={() => setShowArchiveModal(false)}
            title="Archive Confirmation"
            primaryAction={{
              content: 'Archive Project',
              destructive: true,
              onAction: () => archiveProject(project?.id),
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => setShowArchiveModal(false),
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <p>Are you sure you want to archive this project? This will hide the project by default.</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {project?.status === 'archived' ? (
                <Grid container>
                  <Grid item xs={6}>
                    <Button
                      loading={projectSubmitting}
                      onClick={() => {
                        updateProject(project?.id, { status: 'inactive' });
                      }}
                    >
                      Unarchive Project
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={6}>
                    <Button
                      destructive
                      onClick={() => {
                        setShowArchiveModal(!showArchiveModal);
                      }}
                    >
                      Archive Project
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <br />
        </>
      )}
    </Grid>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DetailPageSkeleton />;

  const pageMarkup = projectLoading || !project?.id ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default ProjectDetail;
