import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { Icon } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import IconForm from '../components/IconForm';
import DeleteIconConfirmModal from '../components/DeleteIconConfirmModal';
import Page from '../../shared/components/Page';
import BackButton from '../../navigation/components/BackButton';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditIconPage: React.FC = () => {
  const { iconId } = useParams<{ iconId: string }>();
  const history = useHistory();

  const [formValues, setFormValues] = useState<Icon>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();
  const [deleteIconConfirmModalOpen, setDeleteIconConfirmModalOpen] = useState<boolean>(false);

  const handleEditSubmit = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`icon[${key}]`, value);
    });

    try {
      await axios.put(`icons/${iconId}`, combinedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      history.push(`/admin/icons`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (iconId) {
      axios.get(`icons/${iconId}`).then((response) => {
        setFormValues(response?.data?.result);
      });
    }
  }, [iconId]);

  return (
    <>
      <BackButton pageAction={false} url="/admin/icons" />
      <DeleteIconConfirmModal
        iconId={iconId}
        deleteIconConfirmModalOpen={deleteIconConfirmModalOpen}
        setDeleteIconConfirmModalOpen={setDeleteIconConfirmModalOpen}
      />
      <Page fullWidth title="Edit Icon">
        <Grid container>
          <Grid item xs={12}>
            <IconForm
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formErrors}
              setImageFileData={setImageFileData}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={3}>
              <Grid item>
                <Button onClick={() => handleEditSubmit()} style={siteWideStyles.primaryButton}>
                  Update
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => setDeleteIconConfirmModalOpen(true)} style={siteWideStyles.destructiveButton}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default EditIconPage;
