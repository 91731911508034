import React, { useCallback, useState } from 'react';
import { Card, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';
import OpportunityFilterBar from '../components/OpportunityFilterBar';
import OpportunitiesList from '../components/OpportunitiesList';

const OpportunitiesListContainer: React.FunctionComponent = () => {
  return (
    <Page
      fullWidth
      title="All Opportunities"
      primaryAction={{
        content: 'Create Opportunity',
        url: '/admin/opportunities/new',
      }}
    >
      <Card>
        <OpportunityFilterBar />
        <OpportunitiesList />
      </Card>
    </Page>
  );
};

export default OpportunitiesListContainer;
