import React, { useState, useContext } from 'react';
import { Button, Card } from '@shopify/polaris';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import { Phase } from '../model';
import RespondentForm from '../../respondents/components/RespondentForm';
import PhaseRespondentsList from './PhaseResponentsList';
import PhaseRespondentsFilterBar from './PhaseRespondentsFilterBar';
import { PhaseRespondentsContext } from '../contexts/PhaseRespondentsContext';

// TODO: Convert shopify components to material-ui

interface PhaseRespondentsProps {
  phase: Phase;
}

const PhaseRespondents: React.FC<PhaseRespondentsProps> = (props) => {
  const { phase } = props;
  const history = useHistory();

  const { editAllOpen, setEditAllOpen, selectedRespondentIds } = useContext(PhaseRespondentsContext);

  const [modalOpen, setModalOpen] = useState(false);

  const handleBulkPaymentsPressed = () => {
    sessionStorage.setItem('selectedRespondentIds', JSON.stringify(selectedRespondentIds));
    history.push(`/admin/bulk-payments?project_id=${phase?.project_id}&phase_id=${phase?.id}`);
  };

  return (
    <>
      <ConfirmCancelModal isOpen={modalOpen} onCancel={() => setModalOpen(false)} title="Add Outside Respondent">
        <div style={{ margin: '40px 20px' }}>
          <RespondentForm
            respondent={{ phase_id: phase?.id }}
            afterAction={() => history.push(`/admin/phases/${phase?.id}`)}
          />
        </div>
      </ConfirmCancelModal>
      <Card sectioned>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h4">Respondents</Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item>
                <Button
                  primary={selectedRespondentIds.length > 0}
                  disabled={selectedRespondentIds.length === 0}
                  onClick={handleBulkPaymentsPressed}
                >
                  Bulk Payments {selectedRespondentIds.length.toString()}
                </Button>
              </Grid>
              <Grid item>
                {editAllOpen ? (
                  <Button primary onClick={() => setEditAllOpen(false)}>
                    Save
                  </Button>
                ) : (
                  <Button primary onClick={() => setEditAllOpen(true)}>
                    Edit All
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button primary url={`/admin/phases/${phase?.id}/survey-mapping`}>
                  Qualtrics
                </Button>
              </Grid>
              <Grid item>
                <Button primary onClick={() => setModalOpen(!modalOpen)}>
                  Add Respondents
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PhaseRespondentsFilterBar />
        <PhaseRespondentsList phase={phase} />
      </Card>
    </>
  );
};

export default PhaseRespondents;
