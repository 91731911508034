import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Loading, Page, Toast } from '@shopify/polaris';
import { SurveyMappingContext } from '../contexts/SurveyMappingContext';
import SurveyMappingForm from '../components/SurveyMappingForm';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const EditSurveyMapping: React.FunctionComponent = () => {
  const { phaseId } = useParams<Record<string, string>>();
  const [surveyMappingFetched, setSurveyMappingFetched] = React.useState(false);

  const { surveyMapping, fetchSurveyMapping, surveyMappingsLoading } = React.useContext(SurveyMappingContext);

  React.useEffect(() => {
    if (!surveyMappingFetched) {
      fetchSurveyMapping(phaseId);
      setSurveyMappingFetched(true);
    }
  }, [surveyMappingFetched, fetchSurveyMapping, phaseId]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;
  const loadingMarkup = surveyMappingsLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Edit Survey Mapping">
        <Layout>
          <Layout.Section>
            <SurveyMappingForm surveyMapping={surveyMapping} />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = surveyMappingsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default EditSurveyMapping;
