import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import {
  UploadFile,
  Merge,
  FormatQuote,
  JoinFull,
  MedicalServices,
  LocalHospital,
  People,
  Label,
  Engineering,
  Face,
  AttachMoney,
  PermPhoneMsg,
  EventAvailable,
  Celebration,
  PostAdd,
  PhotoLibrary,
  LocalLibrary,
  AssignmentInd,
  Topic,
  Group,
} from '@mui/icons-material';

import AdminPageHeader from './components/AdminPageHeader';

const allTools = [
  {
    name: 'Insighter Attributes',
    buttons: [
      {
        name: 'Conditions',
        description: '',
        icon: LocalHospital,
        link: '/admin/conditions',
      },
      {
        name: 'Condition Categories',
        description: '',
        icon: MedicalServices,
        link: '/admin/conditions/categories',
      },
      {
        name: 'Ethnicities',
        description: '',
        icon: People,
        link: '/admin/ethnicities',
      },
      {
        name: 'Insighter Roles',
        description: '',
        icon: Face,
        link: '/admin/insighter-roles',
      },
      {
        name: 'Insighter Tags',
        description: '',
        icon: Label,
        link: '/admin/insighter-tags',
      },
      {
        name: 'Specialties',
        description: '',
        icon: Engineering,
        link: '/admin/specialties',
      },
    ],
  },
  {
    name: 'Tools',
    buttons: [
      {
        name: 'List Uploader',
        description: 'Upload a CSV file file to compare with our database.',
        icon: UploadFile,
        link: '/admin/list_uploader',
      },
      {
        name: 'Account Merger',
        description: "Combine two user accounts by moving one users's data to another",
        icon: Merge,
        link: '/admin/account_merger',
      },
      {
        name: 'Condition Merger',
        description: 'Merge two conditions together, then optionally delete one of them',
        icon: JoinFull,
        link: '/admin/conditions/merge',
      },
    ],
  },
  {
    name: 'Back End',
    buttons: [
      {
        name: 'Topics',
        description: 'For organizing and filtering opportunities',
        icon: Topic,
        link: '/admin/topics',
      },
      {
        name: 'Tremendous Campaigns',
        description: '',
        icon: AttachMoney,
        link: '/admin/tremendous-campaigns',
      },
      {
        name: 'Recruitment Partners',
        description: '',
        icon: PermPhoneMsg,
        link: '/admin/recruitment-partners',
      },
      {
        name: 'Calendly Events',
        description: '',
        icon: EventAvailable,
        link: '/admin/calendly-events',
      },
    ],
  },
  {
    name: 'Homepage Resources',
    buttons: [
      {
        name: 'Blog Authors',
        description: '',
        icon: AssignmentInd,
        link: '/admin/blog-authors',
      },
      {
        name: 'Blog Posts',
        description: '',
        icon: PostAdd,
        link: '/admin/blog-posts',
      },
      {
        name: 'Case Studies',
        description: '',
        icon: LocalLibrary,
        link: '/admin/case-studies',
      },
      {
        name: 'Events',
        description: 'Image links for landing page and resource gallery',
        icon: Celebration,
        link: '/admin/event-resources',
      },
    ],
  },
  {
    name: 'Front End',
    buttons: [
      {
        name: 'Testimonials',
        description: 'Insighter quotes that appear on the home page',
        icon: FormatQuote,
        link: '/admin/testimonials',
      },

      {
        name: 'Icons',
        description: 'A collection of icons.  Used for Case Studies and Opportunities.',
        icon: PhotoLibrary,
        link: '/admin/icons',
      },
      {
        name: 'Team Bios',
        description: "Team info that shows on the 'About' page",
        icon: Group,
        link: '/admin/team-bios',
      },
    ],
  },
];

const AdminToolsPage: React.FunctionComponent = () => {
  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Admin Tools" />
      {allTools.map((section) => (
        <Grid item xs={12} key={`section_${section.name}`}>
          <Accordion style={{ marginBottom: 15, backgroundColor: '#545d78' }}>
            <AccordionSummary>
              <Typography variant="h4" style={{ color: 'white', fontWeight: 550 }}>
                {section.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 30 }}>
              <Grid container spacing={2}>
                {section.buttons.map((button) => (
                  <Grid item xs={3} style={{ padding: 5 }} key={button.name}>
                    <a href={button.link} style={{ textDecoration: 'none' }}>
                      <Card style={{ padding: 10, height: '100%' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={3} style={{ textAlign: 'center' }}>
                            <button.icon style={{ width: '100%', height: '100%' }} />
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="h4">{button.name}</Typography>
                            <p>{button.description}</p>
                          </Grid>
                        </Grid>
                      </Card>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminToolsPage;
