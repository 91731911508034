import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Loading, Page, Toast } from '@shopify/polaris';
import { Grid } from '@mui/material';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DashboardNavigation from '../components/DashboardNavigation';
import { InsighterDashboardContext } from '../contexts/InsighterDashboardContext';
import InterviewDashboardCard from '../components/InterviewDashboardCard';
import OpportunitiesGallery from '../../redesignedFrontend/opportunitiesListPage/sections/OpportunitiesGallery';
import BasicFooter from '../../navigation/components/BasicFooter';
import ProfileCompletionTracker from '../components/ProfileCompletionTracker';
import { InsightersContext } from '../../insighters/contexts/InsightersContext';

interface ProjectsDashboardProps {
  isLoading: boolean;
}

const ProjectsDashboard: React.FunctionComponent<ProjectsDashboardProps> = (props) => {
  const { isLoading } = props;
  const history = useHistory();
  const { impersonatedUserId } = useParams<Record<string, string>>();

  const { interviews, fetchDashboardOpportunities, opportunityLoading, recommendedOpportunities } =
    React.useContext(InsighterDashboardContext);

  const { fetchProfileCompletePercentages, profileCompletePercentages } = React.useContext(InsighterDashboardContext);
  const { insighter, fetchInsighter } = React.useContext(InsightersContext);

  const [opportunitiesFetched, setOpportunitiesFetched] = React.useState(false);
  const [insighterFetched, setInsighterFetched] = React.useState(false);
  const [profileCompletePercentagesFetched, setProfileCompletePercentagesFetched] = React.useState(false);

  const loadingMarkup = isLoading ? <Loading /> : null;

  React.useEffect(() => {
    if (!opportunitiesFetched) {
      fetchDashboardOpportunities(impersonatedUserId);
      setOpportunitiesFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunitiesFetched, impersonatedUserId]);

  React.useEffect(() => {
    if (!impersonatedUserId && !profileCompletePercentagesFetched) {
      fetchProfileCompletePercentages();
      setProfileCompletePercentagesFetched(true);
    }
  }, [
    fetchProfileCompletePercentages,
    profileCompletePercentagesFetched,
    setProfileCompletePercentagesFetched,
    impersonatedUserId,
  ]);

  React.useEffect(() => {
    if (impersonatedUserId) {
      if (!insighterFetched) {
        fetchInsighter(impersonatedUserId);
        setInsighterFetched(true);
      }
    }
  }, [insighterFetched, fetchInsighter, impersonatedUserId]);

  const actualPageMarkup = (
    <Page fullWidth>
      <div style={{ minHeight: '70vh' }}>
        <DashboardNavigation currentTab={0} />
        <br />

        <ProfileCompletionTracker profileCompletePercentages={profileCompletePercentages} />

        <div style={{ marginTop: 40, marginLeft: 20, marginRight: 20 }}>
          {interviews.length > 0 && (
            <div>
              <h2 className="Polaris-Heading" style={{ margin: '0 0 25px', fontSize: 28, lineHeight: '32px' }}>
                Your Upcoming Opportunities:
              </h2>
              {interviews.map((interview, index: number) => (
                <div key={interview.id}>
                  <InterviewDashboardCard interview={interview} />
                </div>
              ))}
            </div>
          )}
        </div>
        <br />
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <h2 className="Polaris-Heading" style={{ margin: '0 0 25px', fontSize: 28, lineHeight: '32px' }}>
            Recommended Opportunities
          </h2>
          <Grid container justifyContent="center" alignItems="center">
            <OpportunitiesGallery redesignOpportunities={recommendedOpportunities} />
          </Grid>
        </div>
        <br />
        <br />
      </div>
      <BasicFooter />
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = opportunityLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {impersonatedUserId && (
        <Toast
          onDismiss={() => null}
          content={`Viewing page as ${insighter?.name}`}
          action={{
            content: 'Back',
            onAction: () => history.push(`/admin/insighters/${impersonatedUserId}/view`),
          }}
        />
      )}
    </>
  );
};

export default ProjectsDashboard;
