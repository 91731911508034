import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';

import { TeamBio } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import TeamBioForm from '../components/TeamBioForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditTeamBioPage: React.FC = () => {
  let { teamBioId } = useParams<{ teamBioId: string }>();
  let history = useHistory();

  let [formValues, setFormValues] = useState<TeamBio>();
  let [formErrors, setFormErrors] = useState<string>('');

  let [mainImagePreview, setMainImagePreview] = useState<string | null>(null);
  let [bioImagePreview, setBioImagePreview] = useState<string | null>(null);

  let imageData = [
    {
      name: 'main_image',
      label: 'Main Image',
      preview: mainImagePreview,
    },
    {
      name: 'bio_image',
      label: 'Bio Image',
      preview: bioImagePreview,
    },
  ];

  const editTeamBio = async () => {
    try {
      await axios.put(`team_bios/${teamBioId}`, { team_bio: formValues });
      history.push(`/admin/team-bios`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  const handleImageDrop = async (files: File[], category) => {
    const data = new FormData();
    const attachedFile = files[0];

    if (attachedFile instanceof File === false) {
      return;
    }

    if (attachedFile instanceof File) {
      data.append('file', attachedFile, attachedFile.name);
      data.append('category', category);

      try {
        let response = await axios.put(`/team_bios/${teamBioId}/images`, data);

        if (response?.statusText === 'OK') {
          const previewUrl = URL.createObjectURL(attachedFile);
          if (category === 'main_image') {
            setMainImagePreview(previewUrl);
          } else if (category === 'bio_image') {
            setBioImagePreview(previewUrl);
          }
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      console.error('The dropped file is not a valid type.');
    }
  };

  const fetchTeamBio = async () => {
    try {
      const response = await axios.get(`team_bios/${teamBioId}`);
      setFormValues(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (teamBioId) {
      fetchTeamBio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBioId]);

  useEffect(() => {
    if (formValues?.main_image) {
      setMainImagePreview(formValues?.main_image);
    }
    if (formValues?.bio_image) {
      setBioImagePreview(formValues?.bio_image);
    }
  }, [formValues]);

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={3}>
      <AdminPageHeader title="Edit Team Bio" backButton={{ label: 'Team Bios', link: '/admin/team-bios' }} />
      <Grid item xs={12}>
        <TeamBioForm formValues={formValues} setFormValues={setFormValues} formErrors={formErrors} />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => editTeamBio()} style={siteWideStyles.primaryButton}>
          Save Changes
        </Button>
      </Grid>
      {imageData.map((image) => (
        <>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>{image.label}</Typography>
          </Grid>
          <Grid item xs={6}>
            <DropzoneArea
              acceptedFiles={['image/*']}
              filesLimit={1}
              dropzoneText="Drag and drop an image here or click"
              onChange={(files) => handleImageDrop(files, image?.name)}
            />
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            {image.preview && <img src={image.preview} alt="Preview" style={{ width: 'auto', height: '200px' }} />}
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default EditTeamBioPage;
