import React, { useState, useEffect, Dispatch, SetStateAction, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Grid, Card } from '@material-ui/core';
import { Button } from '@shopify/polaris';
import SelectInput from '../../form/components/SelectInput';
import FormInput from '../../form/components/FormInput';
import { PaymentFormValues } from '../model';
import { payoutTypeChoices, paymentCategoryChoices } from '../constants/paymentChoices';
import { PaymentsContext } from '../contexts/PaymentsContext';
import { User } from '../../auth/model';

import axios from '../../utils/axios.utils';

type PaymentFormProps = {
  formValues: PaymentFormValues;
  setFormValues: Dispatch<SetStateAction<PaymentFormValues>>;
  user?: User;
  setUser?: Dispatch<SetStateAction<User>>;
};

const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  const { formValues, setFormValues, user, setUser } = props;
  const { fetchFormOptions, formOptions } = useContext(PaymentsContext);

  const [phaseOptions, setPhaseOptions] = useState<any[]>([
    { value: '', label: 'Please select a project before selecting a phase.' },
  ]);
  const [formOptionsFetched, setFormOptionsFetched] = useState<boolean>(false);

  const fetchPhaseOptions = async (projectId: string) => {
    const response = await axios.get(`payments/new?project_id=${projectId}`);
    setPhaseOptions(response?.data?.phase_options);
  };

  useEffect(() => {
    if (!formOptionsFetched) {
      fetchFormOptions();
      setFormOptionsFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOptionsFetched]);

  useEffect(() => {
    if (formValues?.project_id) {
      fetchPhaseOptions(formValues?.project_id);
    }
  }, [formValues?.project_id]);

  useEffect(() => {
    if (user?.id) {
      setFormValues({ ...formValues, user_id: user?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let travelCampaign = formOptions?.tremendous_campaign_options?.find((option) =>
    option.label.includes('Travel Stipend'),
  );

  let setCampaignAutomatically = () => {
    if (formValues?.category === 'insighter_travel' && formValues?.tremendous_campaign_id !== travelCampaign?.value) {
      setFormValues({ ...formValues, tremendous_campaign_id: travelCampaign?.value });
    }
  };

  useEffect(() => {
    setCampaignAutomatically();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.category]);

  return (
    <Card style={{ padding: 10 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectInput
            label="Category"
            choices={paymentCategoryChoices}
            onChange={(value) => {
              setFormValues({ ...formValues, category: value });
            }}
            value={formValues?.category}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Description"
            value={formValues?.description}
            onChange={(value) => setFormValues({ ...formValues, description: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Amount"
            value={formValues?.number_amount}
            type="money"
            onChange={(value) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setFormValues({ ...formValues, number_amount: formattedValue });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Payout Type"
            choices={payoutTypeChoices}
            value={formValues?.payout_category}
            onChange={(value: string) => setFormValues({ ...formValues, payout_category: value })}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            label="Project (Optional)"
            choices={formOptions?.project_options}
            onChange={(value) => setFormValues({ ...formValues, project_id: value })}
            value={formValues?.project_id}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Phase (Optional)"
            choices={phaseOptions}
            onChange={(value) => setFormValues({ ...formValues, phase_id: value })}
            value={formValues?.phase_id}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Tremendous Campaign"
            choices={formOptions?.tremendous_campaign_options}
            onChange={(value) => setFormValues({ ...formValues, tremendous_campaign_id: value })}
            value={formValues?.tremendous_campaign_id}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Hold', value: 'hold' },
              { label: 'Incomplete', value: 'incomplete' },
              { label: 'Complete', value: 'complete' },
            ]}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
            value={formValues?.status}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Notes"
            multiline
            rows={4}
            onChange={(value) => setFormValues({ ...formValues, notes: value })}
            value={formValues?.notes}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default PaymentForm;
