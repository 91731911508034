import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';

type PrepareDownloadButtonProps = {
  filename: string;
  visibleColumns?: any;
  link?: string;
  includeResult?: boolean;
};

const PrepareDownloadButton: React.FC<PrepareDownloadButtonProps> = (props) => {
  const [downloadedInfo, setDownloadedInfo] = useState<Record<string, any>[]>();
  const { filename, visibleColumns, link, includeResult } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Prepare Download');
  const columnsToUse = visibleColumns.map((col) => col.name);

  const handlePrepareDownload = () => {
    setDownloadLoading(true);
    axios.get(link, { params: { columns_to_use: columnsToUse } }).then((response) => {
      setDownloadLoading(false);
      if (includeResult) {
        setDownloadedInfo(response?.data?.result?.csv_data);
      } else {
        setDownloadedInfo(response?.data?.csv_data);
      }
    });
  };

  // Set download to empty so that user doesn't think clicking the column alters the download.
  // User must "Prepare Download" to alter the info in the download
  useEffect(() => {
    setDownloadedInfo([]);
  }, [visibleColumns]);

  useEffect(() => {
    if (downloadLoading) {
      setButtonLabel('Loading...');
    } else {
      setButtonLabel('Prepare Download');
    }
  }, [downloadLoading]);

  return (
    <>
      {downloadedInfo && downloadedInfo?.length > 0 ? (
        <CSVLink
          data={downloadedInfo}
          filename={filename}
          style={{ textDecoration: 'none', marginTop: 25, marginRight: 15 }}
        >
          <Button variant="contained" style={siteWideStyles.primaryButton}>
            Get Download
          </Button>
        </CSVLink>
      ) : (
        <Button
          onClick={handlePrepareDownload}
          variant="contained"
          disabled={downloadLoading}
          style={{ ...siteWideStyles.primaryButton, margin: 10 }}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  );
};

export default PrepareDownloadButton;
