import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import useStyles from '../constants/redesignStyles';
import HoverButton from './HoverButton';
import { Opportunity } from '../../opportunities/model';

interface OpportunityScrollerProps {
  hoverButtonText: string;
  hoverButtonLink: string;
  opportunities: Opportunity[];
}

const OpportunityScroller: React.FC<OpportunityScrollerProps> = (props) => {
  const classes = useStyles();

  const { hoverButtonText, hoverButtonLink, opportunities } = props;

  const [imageIndex, setImageIndex] = useState(0);

  const handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? opportunities.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex === opportunities.length - 1 ? 0 : prevIndex + 1));
  };

  let bottomPosition = window.innerWidth <= 960 ? '15px' : '0px';

  return (
    <Grid container className={classes.opportunityScroller}>
      <Grid
        item
        xs={12}
        style={{
          position: 'relative',
          width: '625px',
          height: '274px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          borderRadius: 30,
          overflow: 'hidden',
          backgroundImage: `url(${opportunities[imageIndex]?.thumbnail_image_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Grid
          container
          style={{
            height: '60px',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 10px',
          }}
        >
          <Grid item xs={8} md={10}>
            <a href={`/opportunities/${opportunities[imageIndex]?.id}`} style={{ textDecoration: 'none' }}>
              <Typography
                style={{
                  fontSize: '18px',
                  color: 'white',
                  fontFamily: 'Crimson Text',
                  fontWeight: 600,
                  lineHeight: '1em',
                  zIndex: 2,
                  textDecoration: 'none',
                  bottom: bottomPosition,
                  position: 'relative',
                }}
              >
                {' '}
                {opportunities[imageIndex]?.name}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={4} md={2}>
            <Grid className={classes.arrowButtons} style={{ zIndex: 2 }}>
              <button
                type="button"
                onClick={handlePrevImage}
                style={{ border: '5px solid #EFEFEF', borderRadius: 4, marginRight: 2 }}
              >
                <NavigateBefore />
              </button>
              <button type="button" onClick={handleNextImage} style={{ border: '5px solid #EFEFEF', borderRadius: 4 }}>
                <NavigateNext />
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Grid item xs={9} md={10}>
          <HoverButton text={hoverButtonText} link={hoverButtonLink} />
        </Grid>
        {/* <Grid item xs={3} md={2} style={{ display: 'flex', alignItems: 'center', gap: '5px', paddingLeft: 10 }}>
          {opportunities.map((_, index) => (
            <div
              key={index}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                border: '1px solid black',
                color: 'black',
                backgroundColor: index === imageIndex ? 'black' : 'white',
              }}
            />
          ))}
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default OpportunityScroller;
