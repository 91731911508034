import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Button } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

import axios from '../../utils/axios.utils';

import FormInput from '../../form/components/FormInput';

import { CaseStudy, CaseStudyKeyDetail } from '../model';

import siteWideStyles from '../../styles/siteWideStyles';

interface KeyDetailsComboBoxProps {
  caseStudy: CaseStudy;
  setCaseStudy: Dispatch<SetStateAction<CaseStudy>>;
  keyDetail: CaseStudyKeyDetail;
  index: number;
  setChooseIconModalOpen: Dispatch<SetStateAction<boolean>>;
  setKeyDetailIndexToEdit: Dispatch<SetStateAction<number>>;
}

const KeyDetailsComboBox: React.FC<KeyDetailsComboBoxProps> = (props) => {
  let { caseStudy, setCaseStudy, keyDetail, index, setChooseIconModalOpen, setKeyDetailIndexToEdit } = props;

  let handleIconClicked = (index: number) => {
    setKeyDetailIndexToEdit(index);
    setChooseIconModalOpen(true);
  };

  let handleContentChanged = (value: string) => {
    let keyDetails = [...caseStudy.case_study_key_details];
    keyDetails[index].content = value;

    let updatedCaseStudy = { ...caseStudy, case_study_key_details: keyDetails };
    setCaseStudy(updatedCaseStudy);
  };

  let requestToDeleteExistingKeyDetail = async () => {
    try {
      let response = await axios.delete(`case_studies/${keyDetail.id}/delete_key_detail`);
      let updatedCaseStudy = response.data.result;
      setCaseStudy(updatedCaseStudy);
    } catch (error: any) {
      console.log(error);
    }
  };

  let handleDecreasePositionIndex = () => {
    let keyDetails = [...caseStudy.case_study_key_details];
    let selectedKeyDetail = keyDetails[index];
    let previousKeyDetail = keyDetails[index - 1];

    keyDetails[index].position_index -= 1;
    keyDetails[index - 1].position_index += 1;

    keyDetails[index] = previousKeyDetail;
    keyDetails[index - 1] = selectedKeyDetail;

    let updatedCaseStudy = { ...caseStudy, case_study_key_details: keyDetails };
    setCaseStudy(updatedCaseStudy);
  };

  let removeNewKeyDetail = () => {
    let keyDetails = [...caseStudy.case_study_key_details];
    keyDetails.splice(index, 1);

    let updatedCaseStudy = { ...caseStudy, case_study_key_details: keyDetails };
    setCaseStudy(updatedCaseStudy);
  };

  let handleDeleteKeyDetail = async () => {
    if (keyDetail.id) {
      requestToDeleteExistingKeyDetail();
    } else {
      removeNewKeyDetail();
    }
  };

  return (
    <Grid container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <p>{keyDetail.position_index + 1}</p>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          type="button"
          style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
          onClick={() => handleIconClicked(index)}
        >
          <img
            src={keyDetail.s3_image_url}
            alt="Icon"
            style={{ width: '50px', height: '50px', marginLeft: '8px', cursor: 'pointer' }}
          />
        </button>
      </Grid>
      <Grid item xs={6} style={{ marginRight: 10 }}>
        <FormInput label="Key Detail" value={keyDetail.content} onChange={(value) => handleContentChanged(value)} />
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        {index > 0 && (
          <Button style={siteWideStyles.secondaryButton} onClick={handleDecreasePositionIndex}>
            <ArrowUpward />
          </Button>
        )}
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={siteWideStyles.destructiveButton} onClick={handleDeleteKeyDetail}>
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default KeyDetailsComboBox;
