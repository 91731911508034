import React, { useState, Dispatch, SetStateAction } from 'react';

import { Grid } from '@mui/material';
import { Button } from '@shopify/polaris';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { User } from '../../auth/model';

import axios from '../../utils/axios.utils';

type InsighterLookupProps = {
  setUser?: Dispatch<SetStateAction<User>>;
  initialLookupValue: string;
};

const InsighterLookup: React.FC<InsighterLookupProps> = (props) => {
  const { setUser, initialLookupValue } = props;

  const [lookupValue, setLookupValue] = useState<string>(initialLookupValue);
  const [lookupSubmitting, setLookupSubmitting] = useState<boolean>(false);
  const [lookupError, setLookupError] = useState<string>('');

  const handleLookup = async () => {
    setLookupSubmitting(true);

    await axios
      .get(`payments/lookup`, { params: { lookupValue } })
      .then((response) => {
        setUser(response?.data?.user);
      })
      .catch((error) => {
        setLookupError(error.response.data.error);
      })
      .finally(() => setLookupSubmitting(false));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleLookup();
    }
  };

  return (
    <Grid container spacing={3} style={{ padding: 20 }} onKeyDown={handleKeyPress}>
      <Grid item xs={10}>
        <FormInput
          label="User Email or ID"
          value={lookupValue}
          onChange={(value) => {
            setLookupValue(value);
          }}
        />
      </Grid>
      {lookupError && (
        <Grid>
          <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{lookupError}</p>
        </Grid>
      )}
      <Grid item xs={12} style={{ padding: '20px' }}>
        <Button primary onClick={handleLookup} loading={lookupSubmitting} disabled={!lookupValue}>
          Lookup
        </Button>
      </Grid>
    </Grid>
  );
};

export default InsighterLookup;
