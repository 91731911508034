import React from 'react';
import { Button, Card } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import { LeadGroupsContext } from '../contexts/LeadGroupsContext';
import LeadGroupForm from './LeadGroupForm';
import leadGroupColumns from '../constants/leadGroupColumns';
import { Phase } from '../../projectPhases/model';

const customStyles = {
  cells: {
    style: {
      padding: '8px',
      wordBreak: 'break-word',
    },
  },
};

interface LeadGroupsProps {
  phaseId: string;
  phase: Phase;
}

const LeadGroupsList: React.FC<LeadGroupsProps> = (props) => {
  const { phase, phaseId } = props;
  const history = useHistory();
  const { fetchLeadGroups, leadGroups, leadGroupsLoading } = React.useContext(LeadGroupsContext);
  const [leadGroupsFetched, setLeadGroupsFetched] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!leadGroupsFetched) {
      fetchLeadGroups(`phase_id=${phaseId}`);
      setLeadGroupsFetched(true);
    }
  }, [leadGroupsFetched, fetchLeadGroups, setLeadGroupsFetched, phaseId]);

  const handleUpdateLeadGroup = () => {
    const filterParams = sessionStorage.getItem(`leadGroupFilters-${phaseId}`);
    const queryInputValue = sessionStorage.getItem(`leadGroupFilterQuery-${phaseId}`);
    fetchLeadGroups(`phase_id=${phaseId}`);
  };

  return (
    <>
      <ConfirmCancelModal isOpen={modalOpen} onCancel={() => setModalOpen(false)} title="Create Lead Group">
        <div style={{ margin: '40px 20px' }}>
          <LeadGroupForm phaseId={phaseId} afterAction={() => history.push(`/admin/phases/${phaseId}`)} />
        </div>
      </ConfirmCancelModal>
      <Card sectioned>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Insighter Lead Groups</h2>
            </div>
            <div className="Polaris-Stack__Item">
              <div className="Polaris-ButtonGroup">
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  <Button primary onClick={() => setModalOpen(!modalOpen)}>
                    Create Lead Group
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/lead_groups/${row.id}`)}
          columns={leadGroupColumns(phase, handleUpdateLeadGroup)}
          data={leadGroups}
          pagination
          highlightOnHover
          pointerOnHover
          noHeader
          customStyles={customStyles}
          progressPending={leadGroupsLoading}
        />
      </Card>
    </>
  );
};

export default LeadGroupsList;
