import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Loading, Page, Toast } from '@shopify/polaris';
import InvitationOpportunityForm from '../components/InvitationOpportunityForm';
import FormSkeleton from '../../loading/components/FormSkeleton';
import { InvitationOpportunitiesContext } from '../contexts/InvitationOpportunitiesContext';
import BackButton from '../../navigation/components/BackButton';

const EditInvitationOpportunity: React.FunctionComponent = () => {
  const { invitationOpportunity, fetchInvitationOpportunity, invitationOpportunityLoading } =
    React.useContext(InvitationOpportunitiesContext);
  const [invitationOpportunityFetched, setInvitationOpportunityFetched] = React.useState<boolean>(false);
  const { invitationOpportunityId } = useParams<Record<string, string>>();

  React.useEffect(() => {
    if (!invitationOpportunityFetched) {
      fetchInvitationOpportunity(invitationOpportunityId);
      setInvitationOpportunityFetched(true);
    }
  }, [
    invitationOpportunityFetched,
    fetchInvitationOpportunity,
    invitationOpportunityId,
    setInvitationOpportunityFetched,
  ]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;
  const loadingMarkup = invitationOpportunityLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Edit Invitation Opportunity">
        <Layout>
          <Layout.Section>
            <InvitationOpportunityForm invitationOpportunity={invitationOpportunity} />
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = invitationOpportunityLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default EditInvitationOpportunity;
