/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Card, Button } from '@shopify/polaris';
import { Grid } from '@mui/material';
import { PaymentFormValues } from '../model';
import { PaymentsContext } from '../contexts/PaymentsContext';
import InsighterLookup from './InsighterLookup';
import PaymentForm from './PaymentForm';
import RecipientNameLine from './RecipientNameLine';

import axios from '../../utils/axios.utils';

type Props = Record<string, unknown>;

const NewPaymentContainer: React.FC<Props> = (props) => {
  const { search } = useLocation();
  const { projectId = '' } = useParams<Record<string, string>>();
  let { phaseId = '' } = useParams<Record<string, string>>();
  if (!phaseId) phaseId = new URLSearchParams(search).get('phase_id') || '';
  const email = new URLSearchParams(search).get('email') || '';

  const { setPayment } = useContext(PaymentsContext);

  const [formValues, setFormValues] = useState<PaymentFormValues>({ project_id: projectId, phase_id: phaseId });
  const [formError, setFormError] = useState<string>('');
  const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
  const [disableCreateButton, setDisableCreateButton] = useState<boolean>(true);
  const [user, setUser] = useState<any>({});

  const shouldDisableCreateButton = () => {
    if (!formValues?.category) return true;
    if (!formValues?.status) return true;
    return false;
  };

  useEffect(() => {
    setDisableCreateButton(shouldDisableCreateButton());
  }, [formValues]);

  const handleCreate = async () => {
    try {
      setPaymentSubmitting(true);
      axios
        .post<string, any>(`payments.json`, {
          payment: { ...formValues },
        })
        .then((response) => {
          setPayment(response.data.result);
        })
        .catch((error) => {
          setFormError(error.response.data.error);
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  return (
    <>
      {user?.id ? (
        <Grid container style={{ padding: 10, paddingLeft: 20 }}>
          <Grid item xs={8}>
            <RecipientNameLine user={user} />
          </Grid>
          <Grid item xs={2}>
            <Button primary onClick={() => setUser({})}>
              Change Recipient
            </Button>
          </Grid>
          <Grid item xs={12}>
            <PaymentForm formValues={formValues} setFormValues={setFormValues} user={user} setUser={setUser} />
          </Grid>
          <Grid item xs={12}>
            {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
            <Grid item xs={12} style={{ padding: '20px' }}>
              <Button primary onClick={handleCreate} loading={paymentSubmitting} disabled={disableCreateButton}>
                Create Payment
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <InsighterLookup setUser={setUser} initialLookupValue={email} />
        </Grid>
      )}
    </>
  );
};

export default NewPaymentContainer;
