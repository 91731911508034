import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const listNameColorByStatus = {
  active: 'green',
  published: 'green',
  inactive: 'red',
  upcoming: 'orange',
  draft: 'orange',
  archived: 'LightGray',
};

export const badgeColorByStatus = {
  active: 'MediumAquaMarine',
  published: 'MediumAquaMarine',
  inactive: 'LightCoral',
  upcoming: 'orange',
  draft: 'Wheat',
  archived: 'LightGray',
  incomplete: 'Pink',
  complete: 'MediumAquaMarine',
};

const phaseColumns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Insighter Category',
    selector: 'insighter_category',
    sortable: true,
  },
  {
    name: 'Interview Category',
    selector: 'interview_category',
    sortable: true,
  },
  {
    name: 'Start Date',
    selector: 'start_date_human',
    sortable: true,
    sortFunction: (rowA, rowB) => new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
  },
  {
    name: 'End Date',
    selector: 'end_date_human',
    sortable: true,
    sortFunction: (rowA, rowB) => new Date(rowA.end_date).getTime() - new Date(rowB.end_date).getTime(),
  },

  {
    name: 'Status in TI',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: listNameColorByStatus[status],
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Status in Qualtrics',
    selector: 'active_in_qualtrics',
    sortable: true,
    cell: ({ active_in_qualtrics }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: listNameColorByStatus[active_in_qualtrics],
        }}
      >
        {active_in_qualtrics}
      </div>
    ),
  },
];

export default phaseColumns;
