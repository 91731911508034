import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { Topic } from '../model';
import axios from '../../utils/axios.utils';
import TopicForm from '../components/TopicForm';
import siteWideStyles from '../../styles/siteWideStyles';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const CreateTopicPage: React.FC = () => {
  const history = useHistory();

  const [formValues, setFormValues] = useState<Topic>();
  const [formErrors, setFormErrors] = useState<string>('');

  const createTopic = async () => {
    try {
      await axios.post('topics', formValues);
      history.push(`/admin/topics`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={3}>
      <AdminPageHeader title="Create Topic" backButton={{ link: '/admin/topics', label: 'Topics' }} />
      <Grid item xs={12}>
        <TopicForm formValues={formValues} setFormValues={setFormValues} formErrors={formErrors} />
      </Grid>
      <Grid item xs={3}>
        <Button onClick={() => createTopic()} style={siteWideStyles.primaryButton}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateTopicPage;
