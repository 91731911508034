import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid, Typography, FormControl, FilledInput, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { UserContext } from '../contexts/UserContext';
import axios from '../../utils/axios.utils';
import PasswordBanner from '../components/PasswordBanner';
import siteWideStyles from '../../styles/siteWideStyles';
import Page from '../../shared/components/Page';

const NewPassword: React.FC = () => {
  const { confirmationToken } = useParams<Record<string, string>>();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const { updatePassword, formErrors } = useContext(UserContext);
  const [userMessage, setUserMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const fetchUserFromConfirmationToken = async (confirmation_token = '0') => {
    try {
      await axios.get('passwords', { params: { confirmation_token } });
    } catch (e: any) {
      setUserMessage(e?.response?.data?.error?.password);
    }
  };

  useEffect(() => {
    fetchUserFromConfirmationToken(confirmationToken);
  }, [confirmationToken]);

  return (
    <Page fullWidth title="">
      {userMessage?.length > 0 ? (
        <>
          <Typography variant="h3">{userMessage}</Typography>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#D0D0D0',
              height: 40,
              width: 'auto',
              fontSize: '16px',
              borderRadius: '4px',
              marginTop: 15,
              textTransform: 'capitalize',
              fontFamily: 'Cooper',
            }}
            onClick={() => history.push(`/password-reset`)}
          >
            Request a new link
          </Button>
        </>
      ) : (
        <div style={{ marginTop: 100, marginLeft: 20, marginRight: 20 }}>
          <Grid container>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
              <h1 className="Polaris-Header-Title" style={{ fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10 }}>
                Set Password
              </h1>
              <br />
              <FormControl variant="filled" fullWidth>
                <div className="Polaris-Labelled__LabelWrapper">
                  <div className="Polaris-Label"> Password</div>
                </div>
                <FilledInput
                  id="formInput"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ height: '40px' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      fontSize: '1.4rem',
                      paddingLeft: '10px',
                      paddingTop: '10px',
                    },
                  }}
                />
              </FormControl>
              <Grid style={{ marginTop: '10px' }}>
                <PasswordBanner password={password} />
              </Grid>
              <br />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={3} />
            <Grid item>
              <p style={{ color: 'red' }}>{formErrors?.password}</p>
              <Button
                variant="contained"
                style={siteWideStyles.authButton}
                onClick={() => updatePassword(password, confirmationToken)}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </Page>
  );
};

export default NewPassword;
