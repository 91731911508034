import React from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import siteWideStyles from '../../styles/siteWideStyles';
import { badgeColorByStatus } from '../../projectPhases/constants/phaseColumns';

interface AdminPageHeaderProps {
  title: string;
  backButton?: {
    label: string;
    link: string;
  };
  primaryButton?: {
    label: string;
    link: string;
  };
  secondaryButton?: {
    label: string;
    link: string;
  };
  badge?: {
    label: string;
  };
}

const AdminPageHeader: React.FC<AdminPageHeaderProps> = (props) => {
  let history = useHistory();

  let { title, backButton, secondaryButton, primaryButton, badge } = props;

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {backButton && (
            <Button onClick={() => history.push(backButton?.link)} style={siteWideStyles.secondaryButton}>
              {backButton?.label}
            </Button>
          )}
        </div>
        <div>
          {secondaryButton && (
            <Button onClick={() => history.push(secondaryButton?.link)} style={siteWideStyles.secondaryButton}>
              {secondaryButton.label}
            </Button>
          )}
          {primaryButton && (
            <Button
              onClick={() => history.push(primaryButton?.link)}
              style={{ ...siteWideStyles.primaryButton, marginLeft: 10 }}
            >
              {primaryButton.label}
            </Button>
          )}
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ fontSize: 24, fontWeight: 400 }}>{title}</Typography>
        {badge?.label && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 35,
              backgroundColor: badgeColorByStatus[badge?.label],
              borderRadius: 15,
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 7,
              paddingRight: 7,
              minWidth: 75,
              fontWeight: 500,
              fontSize: 12,
            }}
          >
            {badge?.label?.charAt(0)?.toUpperCase() + badge?.label?.slice(1)}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminPageHeader;
