import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Page, Button } from '@shopify/polaris';
import { Grid } from '@mui/material';
import { CSVLink } from 'react-csv';
import InterviewsList from '../components/InterviewsList';
import InterviewFilterBar from '../components/InterviewFilterBar';
import { UserContext } from '../../auth/contexts/UserContext';
import { InterviewsContext } from '../contexts/InterviewsContext';
import todaysDate from '../../utils/dateFunctions';

const InterviewsListContainer: React.FunctionComponent = () => {
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);
  const { upcomingPayments, interviewsDownload, interviewLoading } = React.useContext(InterviewsContext);

  return (
    <Page fullWidth>
      <Grid container justifyContent="space-between">
        <Grid item>
          <h1 className="Polaris-Header-Title">All Interviews</h1>
        </Grid>
        <Grid item>
          {interviewsDownload && (
            <CSVLink
              data={interviewsDownload}
              filename={`INTERVIEWS-${todaysDate()}.csv`}
              style={{ textDecoration: 'none' }}
            >
              <Button primary>Download Interviews</Button>
            </CSVLink>
          )}
          <span style={{ marginLeft: 7 }} />
          <Button primary onClick={() => history.push(`/admin/interviews/new`)}>
            Create Interview
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <InterviewFilterBar />
        <InterviewsList />
        {currentUserHasRole('financial') && !interviewLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '10px 20px 20px 20px',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>
              <strong>Upcoming Tremendous Payments:</strong> ${upcomingPayments || 0}
            </p>
          </div>
        )}
      </Card>
    </Page>
  );
};

export default InterviewsListContainer;
