import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { EventResource } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import EventResourceForm from '../components/EventResourceForm';
import BackButton from '../../navigation/components/BackButton';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditEventResourcePage: React.FC = () => {
  const { eventResourceId } = useParams<{ eventResourceId: string }>();
  const history = useHistory();

  const [formValues, setFormValues] = useState<EventResource>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const editEventResource = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`event_resource[${key}]`, value);
    });

    try {
      await axios.put(`event_resources/${eventResourceId}`, combinedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      history.push(`/admin/event-resources`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  const fetchEventResource = async () => {
    try {
      const response = await axios.get(`event_resources/${eventResourceId}`);
      setFormValues(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (eventResourceId) {
      fetchEventResource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventResourceId]);

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={3}>
      <AdminPageHeader title="Edit Event" backButton={{ link: '/admin/event-resources', label: 'Events' }} />
      <Grid item xs={12}>
        <EventResourceForm
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setImageFileData={setImageFileData}
        />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => editEventResource()} style={siteWideStyles.primaryButton}>
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditEventResourcePage;
