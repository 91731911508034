import React from 'react';
import { Button } from '@shopify/polaris';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Payment } from '../model';

const getStatusColor = (status: string) => {
  if (!status) {
    return '';
  }

  if (status === 'missing details' || status === 'failed') {
    return 'red';
  }

  if (status === 'incomplete' || status === 'hold') {
    return 'orange';
  }

  if (status === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

const AccountTypeCell: React.FC<{ payment: Payment }> = ({ payment }) => {
  const { user_type: userType, insighter_id: insighterId, uuid } = payment;
  const content = `${uuid} (${userType})`;

  return (
    <div>
      {userType === 'Insighter' ? (
        <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${insighterId}`}>
          {content}
        </a>
      ) : (
        <span>{content}</span>
      )}
    </div>
  );
};

const paymentColumns: IDataTableColumn<any>[] = [
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
  },
  {
    name: 'Category',
    selector: 'payment_category',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'respondent',
    sortable: true,
  },
  {
    name: 'Account Type',
    selector: 'user_type',
    sortable: true,
    cell: (payment: Payment): React.ReactNode => <AccountTypeCell payment={payment} />,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Processed At',
    selector: 'processed_at',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Amount',
    selector: 'number_amount',
    sortable: true,
    cell: ({ number_amount }) => <div>${number_amount}</div>,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status?.toLowerCase()),
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Tremendous Order ID',
    selector: 'tremendous_order_id',
    sortable: true,
  },
  {
    name: 'Actions',
    selector: 'actions',
    sortable: false,
    grow: 2.5,
    cell: (row) => (
      <>
        {row.status === 'incomplete' && (
          <div>
            <Button primary url={`/admin/payments/${row.id}/approve`}>
              Approve
            </Button>
          </div>
        )}
      </>
    ),
  },
];

export default paymentColumns;
