import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, Button } from '@material-ui/core';
import siteWideStyles from '../../styles/siteWideStyles';
import axios from '../../utils/axios.utils';
import { Topic } from '../model';
import DataTable from '../../dataTable';
import DeleteTopicConfirmModal from '../components/DeleteTopicConfirmModal';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const TopicsListPage: React.FC = () => {
  let history = useHistory();

  const [topics, setTopics] = useState<Topic[]>([]);
  const [topicToDelete, setTopicToDelete] = useState<Topic>();
  const [deleteTopicConfirmModalOpen, setDeleteTopicConfirmModalOpen] = useState<boolean>(false);

  const fetchTopics = async () => {
    try {
      const response = await axios.get('topics');
      setTopics(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const handleDeleteButtonClicked = (row: any) => {
    setTopicToDelete(row);
    setDeleteTopicConfirmModalOpen(true);
  };

  const testimonialColumns = [
    { name: 'Name', selector: 'name', sortable: true },
    { name: 'Audience', selector: 'audience', sortable: true },
    { name: 'Status', selector: 'status', sortable: true, grow: 0.5 },
    {
      name: '',
      selector: 'delete',
      sortable: false,
      grow: 0.5,
      cell: (row: any) => (
        <Button
          onClick={() => {
            handleDeleteButtonClicked(row);
          }}
          style={siteWideStyles.destructiveButton}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={2}>
      <DeleteTopicConfirmModal
        topicToDelete={topicToDelete}
        deleteTopicConfirmModalOpen={deleteTopicConfirmModalOpen}
        setDeleteTopicConfirmModalOpen={setDeleteTopicConfirmModalOpen}
      />
      <AdminPageHeader
        title="Topics"
        backButton={{ link: '/admin/tools', label: 'Admin Tools' }}
        primaryButton={{ label: 'Create Topic', link: '/admin/topics/create' }}
      />
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <DataTable
            data={topics}
            columns={testimonialColumns}
            striped
            noHeader
            highlightOnHover
            pointerOnHover
            onRowClicked={(rowData: any) => history.push(`/admin/topics/${rowData.id}/edit`)}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TopicsListPage;
