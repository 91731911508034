import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { EventResource } from '../model';
import axios from '../../utils/axios.utils';
import EventResourceForm from '../components/EventResourceForm';
import siteWideStyles from '../../styles/siteWideStyles';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const CreateEventResourcePage: React.FC = () => {
  const history = useHistory();

  const [formValues, setFormValues] = useState<EventResource>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const createEventResource = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`event_resource[${key}]`, value);
    });

    try {
      await axios.post('event_resources', combinedFormData);
      history.push(`/admin/event-resources`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={3}>
      <AdminPageHeader title="Create Event" backButton={{ label: 'Events', link: '/admin/event-resources' }} />
      <Grid item xs={12}>
        <EventResourceForm
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setImageFileData={setImageFileData}
        />
      </Grid>
      <Grid item xs={3}>
        <Button onClick={() => createEventResource()} style={siteWideStyles.primaryButton}>
          Create Event
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateEventResourcePage;
