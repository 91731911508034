import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { PaymentsContext } from '../contexts/PaymentsContext';

type Props = Record<string, unknown>;

const NewExpenseConfirmation: React.FC<Props> = (props) => {
  const history = useHistory();

  const { payment } = React.useContext(PaymentsContext);

  return (
    <Card title="Newly-Created Expense">
      <Grid container>
        <Grid item xs={2}>
          <Card.Section title="Expense ID">
            <p>{payment.id}</p>
          </Card.Section>
        </Grid>
        <Grid item xs={2}>
          <Card.Section title="Amount">
            <p>$ {payment.number_amount}</p>
          </Card.Section>
        </Grid>
        <Grid item xs={3}>
          <Card.Section title="Category">
            <p>{payment.category}</p>
          </Card.Section>
        </Grid>
        <Grid item xs={12}>
          <Card.Section title="Notes">
            <p>{payment.notes}</p>
          </Card.Section>
        </Grid>
        <Grid item xs={12}>
          <Card.Section title="Description">
            <p>{payment.description}</p>
          </Card.Section>
        </Grid>
        <Grid item xs={3}>
          <Card.Section title="Project Number">
            <p>{payment.project_number}</p>
          </Card.Section>
        </Grid>
        <Grid item xs={3}>
          <Card.Section title="Project Name">
            <p>{payment.project_name}</p>
          </Card.Section>
        </Grid>
        <Grid item xs={3}>
          <Card.Section title="Created At">
            <p>{payment.created_at}</p>
          </Card.Section>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '20px' }}>
        <Grid item xs={3}>
          <Button
            disabled={payment?.phase_id == null}
            onClick={() => history.push(`/admin/phases/${payment?.phase_id}`)}
          >
            Phase
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={() => history.push(`/admin/expenses`)}>Expenses List</Button>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={() => history.push(`/admin/expenses/${payment?.id}`)}>Edit this Expense</Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NewExpenseConfirmation;
