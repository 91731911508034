import React from 'react';
import { Card } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';
import Page from '../../shared/components/Page';
import InsighterFilterBar from '../components/InsighterFilterBar';
import InsightersList from '../components/InsightersList';

const InsightersListContainer: React.FunctionComponent = () => {
  const { search } = useLocation();
  const signupParams = new URLSearchParams(search).get('signups');

  return (
    <Page
      fullWidth
      title={signupParams ? 'Recent Insighters' : 'All Insighters'}
      primaryAction={{
        content: 'Create Insighter',
        url: '/admin/insighters/new',
      }}
    >
      <Card>
        <InsighterFilterBar />
        <InsightersList />
      </Card>
    </Page>
  );
};

export default InsightersListContainer;
