import React from 'react';
import { Card } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import Page from '../../shared/components/Page';
import InternalProjectFilterBar from '../components/InternalProjectFilterBar';
import InternalProjectsList from '../components/InternalProjectsList';

const InternalProjectsListContainer: React.FunctionComponent = () => {
  const history = useHistory();

  return (
    <Page
      fullWidth
      title="Internal Projects"
      primaryAction={{
        content: 'Create Project',
        onAction: () => history.push('/admin/projects/new'),
      }}
      pagination={{
        hasPrevious: true,
        hasNext: true,
      }}
    >
      <Card>
        <InternalProjectFilterBar />
        <InternalProjectsList />
      </Card>
    </Page>
  );
};

export default InternalProjectsListContainer;
