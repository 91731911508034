import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ProjectPhaseForm from '../components/ProjectPhaseForm';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import { badgeColorByStatus } from '../constants/phaseColumns';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditPhase: React.FunctionComponent = () => {
  let { phaseId } = useParams<Record<string, string>>();

  let { phase, phaseAddress, fetchPhase, fetchFormOptions } = useContext(ProjectPhasesContext);

  useEffect(() => {
    fetchFormOptions();
    fetchPhase(phaseId);
  }, [phaseId]);

  let badgeColor = phase?.status === 'active' ? 'lightgreen' : 'lightcoral';

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={`Edit Phase: ${phase?.name}`}
        backButton={{ label: phase?.project_number, link: `/admin/projects/${phase?.project_id}` }}
        badge={{ label: phase?.status }}
      />
      <Grid item xs={12}>
        <Card style={{ padding: '2%' }}>
          <ProjectPhaseForm phase={phase} phaseAddress={phaseAddress} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditPhase;
