import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid, FormControl, FilledInput, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Loading } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import FormInput from '../../form/components/FormInput';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import { RegistrationsContext } from '../contexts/RegistrationsContext';
import PasswordBanner from '../../auth/components/PasswordBanner';
import siteWideStyles from '../../styles/siteWideStyles';

interface OpportunitiesListProps {
  isLoading?: boolean;
}

const RegisterInsighterPage: React.FunctionComponent<OpportunitiesListProps> = (props) => {
  const { isLoading = false } = props;
  const history = useHistory();
  const loadingMarkup = isLoading ? <Loading /> : null;

  const { formErrors, registerUser } = useContext(RegistrationsContext);

  const { search } = useLocation();
  const invitationCode = new URLSearchParams(search).get('invitation_code');
  let email = new URLSearchParams(search).get('email');

  const [urlParamsUpdated, setUrlParamsUpdated] = useState(false);
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setFormValues({ ...formValues, email });
  }, [email]);

  useEffect(() => {
    if (!urlParamsUpdated) {
      if (invitationCode?.match(/[A-Z2-9]{10}/) && invitationCode?.length === 10) {
        setFormValues({ ...formValues, invitation_code: invitationCode, invitation_url: window.location.href });
      }
      setUrlParamsUpdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsUpdated, invitationCode]);

  const handleSignUpClick = () => {
    registerUser(formValues);
  };

  const actualPageMarkup = (
    <Page>
      <Grid container justifyContent="center" style={{ paddingTop: 40 }}>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <h2
            className="Polaris-Header-Title"
            style={{ fontFamily: 'Crimson Text', fontSize: '36px', marginBottom: 10 }}
          >
            Sign Up
          </h2>
        </Grid>
        <Grid item xs={12}>
          {/* <Typography style={styles.insighterAspire}>
            As an Insighter, you can get paid to share your story, your experience, & your insights.
          </Typography>
          <Typography style={styles.insighterAspire}>
            We believe that healthcare research should include all voices & that yours should be in the room where it
            happens.
          </Typography>
          <Typography style={styles.insighterAspire}>We’re eager to pull up a chair & chat.</Typography>
          <Typography style={styles.insighterAspire}>Join us & complete your profile!</Typography> */}
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 40 }}>
        <Grid item md={8} xs={12} style={{ marginRight: 20 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormInput
                type="email"
                label="Email"
                errorMessage={formErrors?.email}
                value={formValues.email}
                onChange={(value) => setFormValues({ ...formValues, email: value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                type="text"
                label="First Name"
                errorMessage={formErrors.first_name}
                value={formValues.first_name}
                onChange={(value) => setFormValues({ ...formValues, first_name: value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                type="text"
                label="Last Name"
                errorMessage={formErrors.last_name}
                value={formValues.last_name}
                onChange={(value) => setFormValues({ ...formValues, last_name: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth>
                <div className="Polaris-Labelled__LabelWrapper">
                  <div className="Polaris-Label"> Password</div>
                </div>
                <FilledInput
                  id="formInput"
                  type={showPassword ? 'text' : 'password'}
                  value={formValues?.password}
                  onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                  style={{ height: '40px' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      fontSize: '1.4rem',
                      paddingLeft: '10px',
                      paddingTop: '10px',
                    },
                  }}
                />
                <p style={{ color: 'red' }}>{formErrors?.password}</p>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <PasswordBanner password={formValues.password} />
              <br />
            </Grid>
            {formValues.invitation_code && (
              <Grid item xs={12}>
                <FormInput type="text" label="Invitation Code" value={formValues.invitation_code} disabled />
              </Grid>
            )}
            <Grid item xs={12} style={{ padding: '6px 16px' }}>
              <p style={{ opacity: 0.7, fontSize: '11px' }}>
                By clicking Sign Up, you agree to our{' '}
                <a target="_blank" href="/terms-of-use">
                  Terms of Use
                </a>
                ,{' '}
                <a target="_blank" href="/pay-portal-terms">
                  Pay Portal Terms
                </a>
                ,{' '}
                <a target="_blank" href="/privacy-policy">
                  Privacy Policy
                </a>
                , and{' '}
                <a target="_blank" href="/cookie-policy">
                  Cookie Policy.
                </a>
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <Button variant="contained" style={siteWideStyles.authButton} onClick={handleSignUpClick}>
                Sign Up
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" style={siteWideStyles.secondaryButton} onClick={() => history.push('/login')}>
                Back to Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );

  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
      {loadingMarkup}
      {pageMarkup}
    </div>
  );
};

export default RegisterInsighterPage;
