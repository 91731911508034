import React, { useContext } from 'react';
import { Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import { PaymentsContext } from '../contexts/PaymentsContext';
import NewPaymentContainer from '../components/NewPaymentContainer';
import NewPaymentConfirmation from '../components/NewPaymentConfirmation';

const NewPaymentPage: React.FunctionComponent = () => {
  const { payment } = useContext(PaymentsContext);

  return (
    <Page fullWidth title="Create New Payment">
      <br />
      <Layout>
        <Layout.Section>{payment?.id ? <NewPaymentConfirmation /> : <NewPaymentContainer />}</Layout.Section>
        <Layout.Section secondary />
      </Layout>
    </Page>
  );
};

export default NewPaymentPage;
