import React, { useCallback, useState, useContext } from 'react';
import { Loading, Toast, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../dataTable/DataTable/DataTable';
import { ProjectsContext } from '../contexts/ProjectsContext';
import projectColumns from '../constants/projectColumns';
import todaysDate from '../../utils/dateFunctions';

const ClientProjectsList: React.FunctionComponent = () => {
  const [projectsFetched, setProjectsFetched] = React.useState(false);

  const {
    projects,
    fetchProjects,
    projectLoading,
    totalRecords,
    sortParams,
    setSortParams,
    filterParams,
    projectsDownload,
  } = useContext(ProjectsContext);

  const { sortProjects } = useContext(ProjectsContext);
  const [isSorting, setIsSorting] = useState(false);

  const toggleSorting = (): void => {
    if (isSorting) {
      sortProjects($('.rdt_TableBody:first').sortable('serialize'));
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  React.useEffect(() => {
    const clientProjectFilters = sessionStorage.getItem('clientProjectFilters');

    if (!projectsFetched) {
      if (clientProjectFilters) {
        fetchProjects(clientProjectFilters);
      } else {
        fetchProjects('ransack[client_name_not_eq]=Sommer Consulting');
      }
      setProjectsFetched(true);
    }
  }, [projectsFetched, fetchProjects]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = projectLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_number',
      name: 'name',
      client_name: 'client_name',
      start_date: 'project_timeline_overall_start_date',
      end_date: 'project_timeline_overall_end_date',
      project_manager_name: 'project_manager_last_name',
      recruiter_name: 'recruiter_last_name',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchProjects(`ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchProjects(`page=${page}&ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sortParams}`);
  };

  const pageMarkup = (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
        <Grid style={{ marginLeft: 20 }}>
          <Button primary={!isSorting} onClick={() => toggleSorting()}>
            {isSorting ? 'Save Order' : 'Sort Projects'}
          </Button>
        </Grid>
      </Grid>

      <DataTable
        onRowClicked={(row: Record<string, string>) => history.push(`/admin/projects/${row.id}`)}
        columns={projectColumns()}
        data={projects}
        progressPending={projectLoading}
        striped
        highlightOnHover
        pointerOnHover
        noHeader
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationServer
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleColumnSort}
      />
    </>
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default ClientProjectsList;
