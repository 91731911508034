import React from 'react';
import { Card } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import Page from '../../shared/components/Page';
import ClientProjectFilterBar from '../components/ClientProjectFilterBar';
import ClientProjectsList from '../components/ClientProjectsList';

const ClientProjectsListContainer: React.FunctionComponent = () => {
  const history = useHistory();

  return (
    <Page
      fullWidth
      title="Client Projects"
      primaryAction={{
        content: 'Create Project',
        onAction: () => history.push('/admin/projects/new'),
      }}
      // pagination={{
      //   hasPrevious: true,
      //   hasNext: true,
      // }}
    >
      <Card>
        <ClientProjectFilterBar financial={false} />
        <ClientProjectsList />
      </Card>
    </Page>
  );
};

export default ClientProjectsListContainer;
